<template>
  <div class="mainContainer">
    <img src="../assets/mitteldeutsche_logo_circle.svg" width="150" height="150">
    <p style="font-size: 120%;"><b>Die von Ihnen gesuchte Seite ist leider nicht verfügbar.</b></p>
    <a-button type="primary" size ="large" @click="handleReturn()"><b>Zurück zum Dashboard</b></a-button>
    </div>
</template>

<script>
//import logapi from '@/apis/logapis'

export default {
  name: 'NotFound',
  data () {
    return {
    
    }
  },
  methods: {
    handleReturn ( ) {
      this.$router.push({ path: '/globalLayout'})
    }
  }
}
</script>
  
<style scoped>
.mainContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-image: url('../assets/Group-3581.svg');
  width: 100vw;
  height: 100vh;
}
</style>