<template>
  <div class="centering">
    <span v-if="this.newsData === null && this.$store.getters.getRole !== 'Admin'"> Keine aktuellen Neuigkeiten!</span>
    <!-- CREATE NEWS -->
    <a-modal
      style="height: 50em;"
      v-model:open="showCreateNewsModal"
      :title="'News hinzufügen'"
      :closable="true"
      cancelText="Abbrechen"
      @cancel="closeCreateModal"
      @ok="handleCreateNewsSubmit"
      destroyOnClose
      :confirmLoading="createLoading"
      centered>
      <a-form
        :ref="formRefCreateNews"
        :model="formCreateNews"
        :rules="rulesNews"
        :loading="createLoading"
        layout="vertical"
        @finish="handleCreateNewsSubmit">
        <a-row>
          <a-col :lg="12">
            <a-form-item ref="type" name="type">
              <template #label>
                <tooltip-label :title="'Art'" :tooltip="'Art der Neuigkeit'"/>
              </template>
              <a-select v-model:value="formCreateNews.type" :placeholder="'Art'">
                <a-select-option value="Wartung"></a-select-option>
                <a-select-option value="Alarm"></a-select-option>
                <a-select-option value="Neuerung"></a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
        </a-row>
        <a-form-item ref="title" name="title">
          <template #label>
            <TooltipLabel :title="'Titel'" :tooltip="'Kurze Beschreibung der Neuigkeit und gegebenenfalls die Dauer.'"/>
          </template>
          <a-input v-model:value="formCreateNews.title" :placeholder="'Titel und gegebenenfalls Dauer'" />
        </a-form-item>
        <a-row :xs="32">
          <a-col :md="24" :lg="24">
            <a-form-item ref="details" name="details">
              <template #label>
                <TooltipLabel :title="'Beschreibung'" :tooltip="'Ausführliche Beschreibung der Neuigkeit. Kann auch leer gelassen werden.'"/>
              </template>
              <a-textarea v-model:value="formCreateNews.details" :placeholder="'Beschreibung'" style="height: 80px;"/>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row :gutter="12">
          <a-col :md="24" :lg="12">
            <a-form-item  name="checked">
              <template #label>
                <tooltip-label :title="'Beschränkte Anzeigedauer'" :tooltip="'Durch das Aktivieren können Sie den genauen Zeitraum einstellen, in welchem diese Neuigkeit sichtbar sein soll. Falls Sie diesen nicht einstellen sollten, bleibt die Neuigkeit bis zur manuellen Änderung immer sichtbar.'"/>
              </template>
              <a-switch v-model:checked="isChecked" @change="changeSwitch()"/>
            </a-form-item> 
          </a-col>
        </a-row>
        <a-row v-if="isChecked" :gutter="12">
          <a-col :md="24" :lg="24">
            <a-form-item ref="runningTime" name="runningTime">
              <template #label>
                <tooltip-label :title="'Anzeigedauer'" :tooltip="'Anzeigedauer der Neuigkeit. Beschreibt nicht die Dauer der Neuigkeit, sondern in welchem Zeitraum diese sichtbar sein soll.'"/>
              </template>
              <a-range-picker
                v-model:value="formCreateNews.runningTime"
                :show-time="{ format: 'HH:mm' }"
                format="YYYY-MM-DD HH:mm"
                :placeholder="['Start Time', 'End Time']"
              />
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </a-modal>
    <!-- EDIT NEWS -->
    <a-modal
      style="height: 50em;"
      v-model:open="showEditNewsModal"
      :title="'News bearbeiten'"
      :closable="true"
      cancelText="Abbrechen"
      @cancel="closeEditModal"
      @ok="handleEditNewsSubmit"
      destroyOnClose
      :confirmLoading="editLoading"
      centered>
      <a-form
        :ref="formRefEditNews"
        :model="formEditNews"
        :rules="rulesNews"
        :loading="editLoading"
        layout="vertical"
        @finish="handleEditNewsSubmit">
        <a-row>
          <a-col :lg="12">
            <a-form-item ref="type" name="type">
              <template #label>
                <tooltip-label :title="'Art'" :tooltip="'Art der Neuigkeit'"/>
              </template>
              <a-select v-model:value="formEditNews.type" :placeholder="'Art'">
                <a-select-option value="Wartung"></a-select-option>
                <a-select-option value="Alarm"></a-select-option>
                <a-select-option value="Neuerung"></a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
        </a-row>
        <a-form-item ref="title" name="title">
          <template #label>
            <TooltipLabel :title="'Titel'" :tooltip="'Kurze Beschreibung der Neuigkeit und gegebenenfalls die Dauer.'"/>
          </template>
          <a-input v-model:value="formEditNews.title" :placeholder="'Titel und gegebenenfalls Dauer'" />
        </a-form-item>
        <a-row>
          <a-col :md="24" :lg="24">
            <a-form-item ref="details" name="details">
              <template #label>
                <TooltipLabel :title="'Beschreibung'" :tooltip="'Ausführliche Beschreibung der Neuigkeit. Kann auch leer gelassen werden.'"/>
              </template>
              <a-textarea v-model:value="formEditNews.details" :placeholder="'Beschreibung'" style="height: 80px;"/>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row :gutter="12">
          <a-col :md="24" :lg="12">
            <a-form-item  name="checked">
              <template #label>
                <tooltip-label :title="'Beschränkte Anzeigedauer'" :tooltip="'Durch das Aktivieren können Sie den genauen Zeitraum einstellen, in welchem diese Neuigkeit sichtbar sein soll. Falls Sie diesen nicht einstellen sollten, bleibt die Neuigkeit bis zur manuellen Änderung immer sichtbar.'"/>
              </template>
              <a-switch v-model:checked="isChecked" @change="changeSwitch()"/>
            </a-form-item> 
          </a-col>
        </a-row>
        <a-row v-if="isChecked" :gutter="12">
          <a-col :md="24" :lg="24">
            <a-form-item ref="runningTime" name="runningTime">
              <template #label>
                <tooltip-label :title="'Anzeigedauer'" :tooltip="'Anzeigedauer der Neuigkeit. Beschreibt nicht die Dauer der Neuigkeit, sondern in welchem Zeitraum diese sichtbar sein soll.'"/>
              </template>
              <a-range-picker
                v-model:value="formEditNews.runningTime"
                :show-time="{ format: 'HH:mm' }"
                format="YYYY-MM-DD HH:mm"
                :placeholder="['Start Time', 'End Time']"
              />
            </a-form-item>
          </a-col>
        </a-row>
        <a-row v-if="isRemoved" :gutter="12">
          <a-col :md="24" :lg="24">
            <a-form-item  name="removed" ref="removed">
              <template #label>
                <tooltip-label :title="'Wiederherstellen der Neuigkeit'" :tooltip="'Möchten Sie die gelöschte Neuigkeit wiederherstellen?'"/>
              </template>
              <a-switch v-model:checked="restoreNews"/>
            </a-form-item> 
          </a-col>
        </a-row>
      </a-form>
    </a-modal>
    <!-- DELETE MODAL -->
    <a-modal
      v-if="showDeleteNewsModal"
      style="top: 20px;"
      centered
      :title="deleteObject.title + ' entfernen'"
      :closable="true"
      :loading="deleteLoading"
      :maskClosable="false"
      v-model:open="showDeleteNewsModal"
    >
      <div style="display: flex; justify-content: center;">
        <DeleteOutlined :style="{fontSize: '40px', color: 'red'}" />
        <span style="margin-left: 20px;"> {{ 'Sind Sie sich sicher, dass Sie wirklich die Neuigkeit "'+ deleteObject.title+'" löschen möchten?' }}</span>
      </div>
      <template #footer style="text-align: right;">
        <a-button type="default" @click="closeDeleteModal()">Abbrechen</a-button>
        <a-button type="primary" :loading="deleteLoading" @click="handleDeleteNewsSubmit()">Löschen!</a-button>
      </template>
    </a-modal>
    <div v-if="this.newsData === null && this.$store.getters.getRole === 'Admin'" style="display: flex; flex-direction: column; align-items: center">
      <a-tooltip placement="top" >
        <template #title>
          {{ 'Neuigkeit erstellen' }}
        </template>
        <a-button v-if="this.$store.getters.getRole === 'Admin'" class="createButton" @click="showCreateModal"><PlusOutlined /></a-button>
      </a-tooltip>
      Neuigkeit hinzufügen
    </div>
    <a-button v-if="this.newsData !== null && this.newsData.length > 0 && this.$store.getters.getRole === 'Admin'" class="createButton" @click="showCreateModal"><PlusOutlined /></a-button>
    <div v-if="this.currentNews !== null && this.currentNews.length > 0 && this.$store.getters.getRole === 'Admin'" class="caption">Aktive News</div>
    <div v-if="this.currentNews !== null && this.currentNews.length > 0" :class="this.$store.getters.getRole === 'Admin' ? 'currentNewsTableContainer': 'newsTableContainer'">
      <a-card v-for="record in currentNews" :id="record.Id" :bordered="false" style="margin: 5px;">
        <template #title>
          <div style="display: flex; justify-content: space-between; flex-direction: row; align-items: center;">
            <div style=" width: fit-content;  font-weight: bold;">{{record.EffectiveDate + " " + record.Title}}</div>
            <div v-if="this.$store.getters.getRole === 'Admin'" style=" width: fit-content;">
              <a-button @click="showEditModal($event, record.Id, record.Title, record.Details, record.Type, record.RunningFromFormatted, record.RunningUntilFormatted, record)" class="tableButton editButton"><EditOutlined /></a-button>
              <a-button @click="showDeleteModal($event, record.Id, record.Title)" type="primary" class="tableButton"><DeleteOutlined /></a-button>
            </div>
          </div>
        </template>
        <div style="display: flex; justify-content: left;">
          <span class="details">{{record.Details}}</span>
        </div>
      </a-card>
    </div>
    <div v-if="this.pastNews !== null && this.pastNews.length > 0 && this.$store.getters.getRole === 'Admin'" class="caption">Vergangene, zukünftige oder gelöschte News</div>
    <div v-if="this.pastNews !== null && this.pastNews.length > 0 && this.$store.getters.getRole === 'Admin'" class="pastNewsTableContainer">
      <a-card v-for="record in pastNews" :id="record.Id" :bordered="false" style="margin: 5px;" :class="'outdatedElement'">
        <template #title>
          <div style="display: flex; justify-content: space-between; flex-direction: row; align-items: center;">
            <div style=" width: fit-content;  font-weight: bold;">{{record.EffectiveDate + " " + record.Title}}</div>
            <div v-if="this.$store.getters.getRole === 'Admin'" style=" width: fit-content;">
              <a-button @click="showEditModal($event, record.Id, record.Title, record.Details, record.Type, record.RunningFromFormatted, record.RunningUntilFormatted, record)" class="tableButton editButton"><EditOutlined /></a-button>
              <a-button v-if="record.Removed === null" @click="showDeleteModal($event, record.Id, record.Title)" type="primary" class="tableButton"><DeleteOutlined /></a-button>
            </div>
          </div>
        </template>
        <div style="display: flex; justify-content: left;">
          <span class="details">{{record.Details}}</span>
        </div>
      </a-card>
    </div>
  </div>
</template>

<script>
import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons-vue'
import dayjs from 'dayjs';
import { ref, reactive, toRaw } from 'vue'
import TooltipLabel from '@/components/TooltipLabel.vue'
import notification from 'ant-design-vue'
import logapi from '@/apis/logapis'

export default {
  name: 'News',
  components: {
    EditOutlined,
    DeleteOutlined,
    PlusOutlined,
    TooltipLabel,
    notification
  },
  props: {
    clicked: {
      required: false,
      type: Number
    }
  },
  data () {
    return {
      newsCols:[
        {
          key: 'type',
          dataIndex: 'Type',
          width:'10%',
        },
        {
          key: 'title',
          dataIndex: 'Title',
          width: '80%',
        },
        
        {
          key: 'edit',
          dataIndex: 'edit',
          align: 'right',
          width: '20%',
        }
      ],
      newsData: [],
      expandedKeys:[],
      details:'',
      showCreateNewsModal: false,
      showEditNewsModal: false,
      showDeleteNewsModal: false,
      createLoading:false,
      editLoading:false,
      deleteLoading:false,
      tableLoading:false,
      deleteObject: [],
      dateTimeRegex:/^(0[1-9]|[12][0-9]|3[01])[-](0[1-9]|1[012])[-](20)\d\d ([01][01]?[0-9]|2[0-3]):[0-5][0-9]$/,
      isChecked: false,
      timeoutId: "",
      currentTime: Date.now(),
      currentNews: [],
      pastNews: [],
      isRemoved: false,
      restoreNews: false
    }
  },
  watch:{
    clicked () {
      if (Object.keys(this.$store.getters.getHighlightedNews).length !== 0) {
        this.highlightObject()
      }
    }
  },
  created () {
    this.initForm()
  },
  mounted () {
    if (this.$store.getters.getRole !== 'Admin') {
      this.getCurrentNews()
    } else {
      this.getAllNews()
    }
  },
  updated () {
    if (Object.keys(this.$store.getters.getHighlightedNews).length !== 0) {
      this.highlightObject()
    }
  },
  methods: {
    initForm () {
      this.formRefCreateNews = ref()
      this.formCreateNews =  reactive({
        title: '',
        type: '',
        details: '',
        runningTime: [],
      })
      this.formRefEditNews = ref()
      this.formEditNews =  reactive({
        id: '',
        title: '',
        type: '',
        details: '',
        runningTime: [],
      })
      this.rulesNews = reactive({
        title: [
          { required: true, message: '' },
          { validator: this.validateTitle}
        ],
        type: [
          { required: true, message: 'Bitte geben Sie eine Art an!' },
        ],
        runningTime: [
          {validator: this.runningTimeValidator}
        ]
      })
    },
    getCurrentNews () {
      logapi.getNews().then(response => {
        this.newsData = response.data
        if (response.data !== null) {
          this.currentNews = []
          this.pastNews = []
          for (let i = 0; i <  response.data.length; i++) {
            if (this.newsData[i].Details === '' || this.newsData[i].Details === null) {
              this.newsData[i].Details = 'Keine Beschreibung vorhanden...'
            }
            this.newsData[i].EffectiveDate = new Date(Date.parse(this.newsData[i].EffectiveDate)).toLocaleDateString()
            this.currentNews.push(this.newsData[i])
          }
        } 
      }).catch(error => {
        console.log(error)
        this.$notification.error({
          message: 'Fehler',
          description: error.response.data
        })
      })
    },
    getAllNews () {
      logapi.getAllNews().then(response => {
       this.newsData = response.data
       console.log(this.newsData)
        if (response.data !== null) {
          this.currentNews = []
          this.pastNews = []
          for (let i = 0; i <  response.data.length; i++) {
            if (this.newsData[i].Details === '' || this.newsData[i].Details === null) {
              this.newsData[i].Details = 'Keine Beschreibung vorhanden...'
            }
            this.newsData[i].EffectiveDate = (new Date(Date.parse(this.newsData[i].EffectiveDate))).toLocaleDateString()

            if ((this.newsData[i].RunningFromFormatted === '' || (new Date(this.newsData[i].RunningUntil).getTime() > this.currentTime && new Date(this.newsData[i].RunningFrom).getTime() < this.currentTime)) && this.newsData[i].Removed === null){
              this.currentNews.push(this.newsData[i])
            } else {
              this.pastNews.push(this.newsData[i])
            }
          }
        } 
      }).catch(error => {
        console.log(error)
        this.$notification.error({
          message: 'Fehler',
          description: error.response.data
        })
      })
    },
    validateTitle (rule, value) {
      const errorMessage = 'Bitte geben Sie einen Titel mit maximal 90 Zeichen an!'
      if (value.length <= 90 && value.length > 0) {
        return Promise.resolve()
      }
      return Promise.reject(errorMessage)
    }, 
    runningTimeValidator (rule, value) {
      const errorMessage = 'Bitte geben Sie einen Zeitraum an!'
      if (this.isChecked && value) {
        return Promise.resolve()
      }
      if (!this.isChecked && value.length === 0) {
        return Promise.resolve()
      }
      return Promise.reject(errorMessage)
    },
    getNewsDetails (expanded, record) {
      this.expandedKeys = []
      if (expanded){
        this.expandedKeys.push(record.Id)
      }
      this.details = record.Details
    },
    showEditModal (event, id, title, details, type, runningFrom, runningUntil, record) {
      event.stopPropagation()
      if (runningFrom !== '' && runningUntil !== '') {
        const runningFromFormatted = dayjs(new Date(Date.parse(runningFrom)));
        const runningUntilFormatted = dayjs(new Date(Date.parse(runningUntil)));
        const runningTimeFormatted = [runningFromFormatted, runningUntilFormatted]
        this.formEditNews.runningTime = runningTimeFormatted 
        this.isChecked = true
      } else {
        this.formEditNews.runningTime = []
      }
      if (record.Removed !== null) {
        this.isRemoved = true
      } else {
        this.isRemoved = false
      }
      this.formEditNews.id = id
      this.formEditNews.title = title
      this.formEditNews.details = details
      this.formEditNews.type = type
      this.showEditNewsModal = true
    },
    showDeleteModal (event, id, title) {
      event.stopPropagation()
      this.deleteObject.id = id
      this.deleteObject.title = title
      this.showDeleteNewsModal = true
    },
    handleCreateNewsSubmit (e) {
      e.preventDefault()
      if (this.createLoading) return
      this.formRefCreateNews.value.validate().then(async () => {
        this.createLoading = true
        const values = toRaw(this.formCreateNews)
        var params = new FormData()
        params.append('type', values.type)
        params.append('title', values.title)
        params.append('details', values.details)
        if (values.runningTime.length > 0) {
          params.append('runningFrom', values.runningTime[0].$d.toLocaleString())
          params.append('runningUntil', values.runningTime[1].$d.toLocaleString())
        } else {
          params.append('runningFrom', '')
          params.append('runningUntil', '')
        }
        
        logapi.createNews(params).then(response =>{
          this.closeCreateModal()
          this.createLoading = false
          this.$notification.success({
            message: 'Erfolg',
            description: 'Die Neuigkeit wurde erfolgreich erstellt.',
            duration: 6
          })
        }).catch(error =>{
          console.log(error)
          this.closeCreateModal()
          this.$notification.error({
            message: 'Fehler',
            description: error.response.data
          })
          this.createLoading = false
        })
      }).catch(error => {
        console.log(error)
        this.$notification.error({
          message: 'Fehler',
          description: 'Fehler beim Validieren der eingegebenen Daten, bitte versuchen Sie es erneut.'
        })
        this.createLoading = false
      })
      if (this.$store.getters.getRole !== 'Admin') {
        this.getCurrentNews()
      } else {
        this.getAllNews()
      }
      this.$emit('get-maintenances')
    },
    handleEditNewsSubmit (e) {
      e.preventDefault()
      if (this.editLoading) return
      this.formRefEditNews.value.validate().then(async () => {
        this.editLoading = true
        const values = toRaw(this.formEditNews)
        var params = new FormData()
        params.append('id', values.id)
        params.append('type', values.type)
        params.append('title', values.title)
        params.append('details', values.details)
        if (values.runningTime.length > 0) {
          params.append('runningFrom', values.runningTime[0].$d.toLocaleString())
          params.append('runningUntil', values.runningTime[1].$d.toLocaleString())
        } else {
          params.append('runningFrom', '')
          params.append('runningUntil', '')
        }
        if (this.restoreNews) {
          params.append('restore', true)
          this.restoreNews = false
        }else {
          params.append('restore', false)
        }
        logapi.editNews(params).then(response =>{
          this.closeEditModal()
          this.editLoading = false
          this.$notification.success({
            message: 'Erfolg',
            description: 'Die Neuigkeit wurde erfolgreich bearbeitet.',
            duration: 6
          })
        }).catch(error =>{
          console.log(error)
          this.closeEditModal()
          this.$notification.error({
            message: 'Fehler',
            description: error.response.data
          })
          this.editLoading = false
        })
      }).catch(error => {
        console.log(error)
        this.$notification.error({
          message: 'Fehler',
          description: 'Fehler beim Validieren der eingegebenen Daten, bitte versuchen Sie es erneut.'
        })
        this.editLoading = false
      })
      if (this.$store.getters.getRole !== 'Admin') {
        this.getCurrentNews()
      } else {
        this.getAllNews()
      }
      this.$emit('get-maintenances')
    },
    handleDeleteNewsSubmit () {
      if (this.deleteLoading) return
      if (this.deleteObject.id === undefined || this.deleteObject.title === undefined) {
        this.$notification.error({
          message: 'Fehler',
          description: 'Fehler beim Übergeben der Daten, bitte versuchen Sie es erneut.'
        }) 
        return
      }
      var params = new FormData()
      params.append('id', this.deleteObject.id)
      logapi.deleteNews(params).then(response =>{
        this.closeDeleteModal()
        this.deleteLoading = false
        this.$notification.success({
          message: 'Erfolg',
          description: 'Die Neuigkeit wurde erfolgreich gelöscht.',
          duration: 6
        })
      }).catch(error =>{
        console.log(error)
        this.closeDeleteModal()
        this.$notification.error({
          message: 'Fehler',
          description: error.response.data
        })
        this.deleteLoading = false
      })
      if (this.$store.getters.getRole !== 'Admin') {
          this.getCurrentNews()
      } else {
        this.getAllNews()
      }
      this.$emit('get-maintenances')
    },
    showCreateModal () {
      this.showCreateNewsModal = true
    },
    closeCreateModal () {
      this.showCreateNewsModal = false
      this.formCreateNews.type = ''
      this.formCreateNews.title = ''
      this.formCreateNews.details = ''
      this.formCreateNews.runningTime = []
      this.isChecked = false
    },
    closeEditModal () {
      this.showEditNewsModal = false
      this.isChecked = false
      this.restoreNews = false
    },
    closeDeleteModal () {
      this.showDeleteNewsModal = false
      this.isChecked = false
    },
    changeSwitch () {
      if (!this.isChecked) {
        this.formCreateNews.runningTime = []
        this.formEditNews.runningTime = []
      }
    },
    highlightObject(){
      clearTimeout(this.timeoutId)
      for (let i = 0; i < this.currentNews.length; i++) {
        document.getElementById(this.currentNews[i].Id).style.backgroundColor = "white"
        document.getElementById(this.currentNews[i].Id).style.boxShadow = "none"
      }
      document.getElementById(this.$store.getters.getHighlightedNews.Id).scrollIntoView()
      for (let i = 0; i < this.currentNews.length; i++) {
        if (this.currentNews[i].Id !== this.$store.getters.getHighlightedNews.Id){
          document.getElementById(this.currentNews[i].Id).style.backgroundColor = "rgba(240, 240, 240, 0.5)"
        } else {
          document.getElementById(this.currentNews[i].Id).style.boxShadow = "0px 0px 10px #D02C2A"
        }
      }
      this.timeoutId = setTimeout(this.revertBackground, 5000);
    },
    revertBackground () {
      for (let i = 0; i < this.currentNews.length; i++) {
        document.getElementById(this.currentNews[i].Id).style.backgroundColor = "white"
        document.getElementById(this.currentNews[i].Id).style.boxShadow = "none"
      }
      this.$store.commit("setHighlightedNews", [])
    },
  }
}
</script>

<style scoped>
.details {
  text-align: left;
  white-space: pre-line;
}
.centering {
  width: 100%;
  height: 95%;
  flex-direction: column; 
  display: flex; 
  justify-content: center; 
  align-items: center;
}
.newsTableContainer {
  width: 50%;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  margin-top: 20px;
}

.currentNewsTableContainer {
  width: 50%;
  height: 65%;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  margin-top: 20px;
}
.pastNewsTableContainer {
  width: 50%;
  height: 30%;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  margin-top: 20px;
}
.headline {
  margin-left: 5px;
}

.tableButton {
  width: 45px;
}

.editButton {
  margin-right: 4px;
}

.createButton {
  margin-top: 5px;
  width: 45px;
}
.outdatedElement {
  background-color:rgba(240, 240, 240, 0.5)
}
.caption {
  font-weight: bold;
}
@media (max-width: 1326px) {
  .currentNewsTableContainer {
    width: 60%;
  }
  .pastNewsTableContainer {
    width: 60%;
  }
  .newsTableContainer {
    width: 60%;
  }
}
@media (max-width: 1026px) {
  .currentNewsTableContainer {
    width: 70%;
  }
  .pastNewsTableContainer {
    width: 70%;
  }
  .newsTableContainer {
    width: 70%;
  }
}
@media (max-width: 768px) {
  .currentNewsTableContainer {
    width: 80%;
  }
  .pastNewsTableContainer {
    width: 80%;
  }
  .newsTableContainer {
    width: 80%;
  }
}
@media (max-width: 451px) {
  .currentNewsTableContainer {
    width: 100%;
  }
  .pastNewsTableContainer {
    width: 100%;
  }
  .newsTableContainer {
    width: 100%;
  }
}
</style>