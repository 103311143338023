import { createStore } from 'vuex'
import createPersistedState from "vuex-persistedstate";

export default createStore({
  state: {
    accountId: "",
    isAuthenticated: false,
    status: "",
    email: "",
    saasURL: "",
    otpValidated: false,
    statusCloudUI: "",
    role:"",
    highlightedNews: [],
    clicked: ""
  },
  mutations: {
      setAccountId (state, loginInput) {
        state.accountId = loginInput
      },
      setAuthentification (state, loginInput) {
        state.isAuthenticated = loginInput
      },
      setStatus (state, loginInput) {
        state.status = loginInput
      },
      setEmail (state, data) {
          state.email = data
      },
      setOtpValidated (state,valid){
          state.otpValidated = valid
      },
      setSaasURL (state, data) {
          state.saasURL = data
      },
      setStatusCloudUI (state,data){
          state.statusCloudUI = data
      },
      setRole (state,role){
        state.role = role
      },
      setHighlightedNews (state,highlightedNews){
        state.highlightedNews = highlightedNews
      },
      setClicked (state,clicked){
        state.clicked = clicked
      },
  },
  actions: {
  },
  modules: {
  },
  getters: {
    getAccountId:state => state.accountId,
    getAuthentification:state => state.isAuthenticated,
    getStatus: state => state.status,
    getEmail: state => state.email,
    getOtpValidated: state => state.otpValidated,
    getSaasUrl: state => state.saasURL,
    getStatusCloudUI: state => state.statusCloudUI,
    getRole: state => state.role,
    getHighlightedNews: state => state.highlightedNews,
    getClicked: state => state.clicked
  },
  plugins:[createPersistedState()]
})

