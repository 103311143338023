<template>
    <div style="display: flex; position: sticky; align-items: center; justify-content: center; height: 50px; background-color: white;">
        <span style="margin-right: 5px;">mitteldeutsche IT</span>
        <img src="../assets/mitteldeutsche_logo_circle.svg" width="20" height="20">
        <span style="margin-left: 5px;">Login Portal</span>
        &nbsp;|<a href="https://mitteldeutsche-it.de/impressum/" target="_blank" style="font-weight: normal;">&nbsp;Impressum</a>
        &nbsp;|<a href="https://mitteldeutsche-it.de/wp-content/uploads/2022/08/Datenschutz.pdf" target="_blank" style="font-weight: normal;">&nbsp;Datenschutz</a>
    </div>
</template>

<script>
    export default {
        name: 'Footer'
    }
</script>

<style scoped>
    a { text-decoration: none;  }
</style>
