import { createRouter, createWebHashHistory } from 'vue-router'
import Login from '../views/Login.vue'
import Dashboard from '@/views/Dashboard.vue'
import News from '@/views/News.vue'
import GlobalLayout from '@/components/GlobalLayout.vue'
import NotFound from '../views/NotFound.vue'
import store from '@/store'
import Cookies from 'js-cookie'
import { notification } from 'ant-design-vue'

const routes = [
  {
    path: '/',
    name: 'Login',
    component: Login
  },
  {
    path: '/globalLayout',
    name: 'GlobalLayout',
    component: GlobalLayout,
    children: [
      {
        path: '/dashboard',
        name: 'Dashboard',
        component: Dashboard,
      },
      {
        path: '/news',
        name: 'News',
        component: News,
      },
    ],
  },
  
   { 
    path: '/:pathMatch(.*)*',  
    name:'NotFound',
    component: NotFound,    
}, 
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

router.beforeEach(async (to, from, next) => {
  if (store.getters.getAuthentification && Cookies.get('session_token')) {
    if (to.path === '/') next('/dashboard')
    else next();
  } else {
    if (to.path === '/') next()
    else {
      next('/');
      notification.error({
        message: 'Sitzung abgelaufen',
        description:
          'Ihre Sitzung ist abgelaufen. Bitte melden Sie sich erneut an.',
        duration: 6
      })
    }
  }
})

export default router
