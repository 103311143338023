<template>
  <div class="dashboardContent">
    <div v-if="saasManagerAccess === true" class="leftContainer flexCenter" >
      <b style="font-size: 200%; margin-bottom: 20px;">SaaS-Manager</b>
      <div class="leftContent" href="https://mitteldeutsche-it.de/" style="position: relative;">
        <div>
          <img src="../assets/mitteldeutsche-it-saas-manager.png" width="270" height="270" class="cloudAndSaaSImage">
        </div>
        <div style="margin-bottom: 20px;">
          <p>Software as a Service <br>Cloud-basierte sofortige Anwendungsbereitstellung von Software und flexible Lizenzierungsmöglichkeiten. </p>
        </div>
      </div>
<!--      <a-button type="primary" size="large" @click="handleSaasManager()" class="button" :href="'https://'+this.saasUrl+'/manager/index.php'" target="_blank"><span class="myCustomLink">SaaS-Manager öffnen</span></a-button>-->
      <a-button type="primary" size="large" @click="handleSaasManager()" class="button" ><span class="myCustomLink">SaaS-Manager öffnen</span></a-button>
    </div>
    <div v-if="cloudAccess === true" class="middleAndRightContainer flexCenter">
      <b style="font-size: 200%; margin-bottom: 20px;">IaaS-Manager</b>
      <div class="middleContent" href="https://mitteldeutsche-it.de/" style="position: relative;">
        <div>
          <img src="../assets/mitteldeutsche-it-IaaS-Manager.png" width="270" height="270" class="cloudAndSaaSImage">
        </div>
        <div style="margin-bottom: 20px;">
          <p>Zugang zum mIT IaaS-Manager</p>
        </div>
      </div>
      <a-button type="primary" size="large" @click="handleCloud()" class="button"><span class="myCustomLink">IaaS-Manager öffnen</span></a-button>
    </div>
    <!-- v-if="partnerPortalAccess === true" -->
    <div class="middleAndRightContainer flexCenter" v-if="partnerPortalAccess === true">
      <b style="font-size: 200%; margin-bottom: 20px;">Partner Portal</b>
      <div class="rightContent" href="https://mitteldeutsche-it.de/" style="position: relative;" id="partnerportal">
        <div>
          <img v-if="partnerPlan === 'Holz'" src="../assets/holz.png" width="240" height="150" class="partnerImage">
          <img v-if="partnerPlan === 'Bronze'" src="../assets/bronze.png" width="240" height="150" class="partnerImage">
          <img v-if="partnerPlan === 'Silber'" src="../assets/silber.png" width="240" height="150" class="partnerImage">
          <img v-if="partnerPlan === 'Gold'" src="../assets/gold.png" width="240" height="150" class="partnerImage">
          <img v-if="partnerPlan === 'Platin'" src="../assets/platin.png" width="240" height="150" class="partnerImage">
        </div>
        <div style="margin-bottom: 20px;">
          <p>Zugang zum Partner Portal <br>Preise, Rabatte und Rechnungen einsehen. Konfigurieren und vergleichen Sie Cloud-Angebote für Ihre Kunden und speichern oder laden das Angebot der konfigurierten Lösung herunter.   </p>
        </div>
      </div>
      <a-button type="primary" size="large" @click="handlePartnerPortal()" class="button" href="https://partner.mitteldeutsche-it.de/#/globallayout" target="_blank"><span class="myCustomLink">Partner Portal öffnen</span></a-button>
    </div>
  </div>
</template>

<script>
import logapi from '@/apis/logapis'
import Cookies from 'js-cookie'

export default {
  name: 'Dashboard',
  components: {
  },
  data () {
    return {
      partnerPlan: "",
      accountid: "",
      saasManagerAccess: false,
      cloudAccess: true,
      partnerPortalAccess: false,
      saasUrl: "",
      statusCloudUi: "",
    }
  },
  mounted () {
    this.fetchData()
  },
  methods: {
    fetchData () {
      // this.checkReferer()
      if(!Cookies.get('session_token')){
        this.$store.commit("setAuthentification", false)
      }
      this.accountid = this.$store.getters.getAccountId
      this.statusCloudUi = this.$store.getters.getStatusCloudUI
      if (this.$store.getters.getAuthentification === true){
        this.getPartnerPlan()
        this.checkForSaasManager()
        //this.checkForCloudUI()
      }
    },
    checkForSaasManager () {
      logapi.checkSaasManager(this.accountid).then(response => {
        if (response.data.Domain === "") {
          this.saasManagerAccess = false
        } else {
          this.saasManagerAccess = true
          this.saasUrl = response.data.Domain
        }
      }).catch(error => {
        console.log(error.response.data.message)
        this.saasManagerAccess = false
      })
    },
    /* checkForCloudUI () {
      api2.checkCloudUI(this.accountid).then(response => {
       // this.cloudAccess = response.data.status
       console.log(response.data.status)
      }).catch(error => {
        console.log(error.message)
        this.cloudAccess = false
      })
    }, */
    async getPartnerPlan () {
      await logapi.getPartnerWithPlan(this.accountid).then(response => {
        if(response.status === 200){
          this.partnerPlan = response.data.plan.name
          this.partnerPortalAccess = response.data.status
        }

      }).catch(error => {
        if(error.response.status === 430){
          console.log(error.response.data.message)
          this.partnerPortalAccess = false
        }

      })
      /* if (this.cloudAccess === true && this.saasManagerAccess === false && this.partnerPortalAccess === true) {
        if (document.getElementById("partnerportal") !== null) {
          document.getElementById("partnerportal").style.borderLeft = "none"
        }
      } */
    },
    async handleCloud() {
      let cloudUiParams = new FormData();
      cloudUiParams.append('domainName', "cloud.mitteldeutsche-it.de")
      await logapi.setCookies(cloudUiParams).then(response => {
        if (response.request.status === 200 ) {
          window.open('https://cloud.mitteldeutsche-it.de/#/user/login/'+response.data.session, '_blank', 'noopener');

        }
      }).catch(error => {
        console.log("error: ",error.response.data.message)
      })
    },
    async handlePartnerPortal () {
      await this.setCookies("partner.mitteldeutsche-it.de")
    },
    async handleSaasManager () {
      let params = new FormData();
      params.append('domainName', this.saasUrl)
      await logapi.setCookies(params).then(response => {
        if (response.request.status === 200 ) {
          window.open('https://'+this.saasUrl+'/manager/index.php?cmd=autologin&username='+response.data.user
              +'&password='+response.data.pass+"&session="+response.data.session, '_blank', 'noopener, noreferrer');

        }
      }).catch(error => {
        console.log("error: ",error.response.data.message)
      })
    },
    async setCookies(domainName){
      let params = new FormData();
      params.append('domainName', domainName)
     await logapi.setCookies(params).then(response => {
        if (response.request.status === 200 ) {
          console.log("Cookies Data Set from Login")
        }
      }).catch(error => {
        console.log("error: ",error.response.data.message)
      })
    }
  }
}
</script>

<style scoped>
  .dashboardContent {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
  }
  .flexCenter {
    display: flex;
    justify-content: center;
  }
  .middleAndRightContainer {
    width: 500px;
    height: 450px;
    align-items: center;
    flex-direction: column;
    user-select: none;
  }
  .leftContainer {
    width: 500px;
    height: 450px;
    align-items: center;
    user-select: none;
    flex-direction: column;

  }
  .middleContent {
    width: 460px;
    height: 450px;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    align-items: center;
    margin-top: 40px;
    padding: 0 20px 0 20px;
  }
  .rightContent {
    width: 460px;
    height: 450px;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    align-items: center;
    margin-top: 40px;
    padding: 0 20px 0 20px;
    border-left: 1px solid rgb(181,181,181);
  }
  .leftContent {
    width: 460px;
    height: 450px;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    align-items: center;
    margin-top: 40px;
    padding: 0 20px 0 20px;
    border-right: 1px solid rgb(181,181,181);
  }
  .partnerImage {
    position: absolute;
    top: -30px;
    left: 130px;
  }
  .button {
    margin-bottom: 20px;
    border-radius: 4px;
    width: 200px;
  }
  .cloudAndSaaSImage {
    position: absolute;
    top: -60px;
    left: 110px;
  }


  /* unvisited link */
  .myCustomLink {
    //background-color: #d02c2a;
    color: #ffffff;
    //padding: 15px 25px;
    //text-align: center;
    //text-decoration: none;
    //display: inline-block;
  }


  @media only screen and (max-width: 1500px) {
  .middleAndRightContainer {
    width: 320px;
  }
  .leftContainer {
    width: 320px;
  }
  .leftContent {
    width: 320px;
  }
  .rightContent {
    width: 320px;
  }
  .middleContent {
    width: 320px;
  }
  .partnerImage {
    left: 40px;
  }
  .cloudAndSaaSImage {
    left: 30px;
  }
}

@media only screen and (max-width: 970px) {

  .middleAndRightContainer {
    width: 300px;
  }
  .leftContainer {
    width: 300px;
  }
  .leftContent {
    width: 300px;
  }
  .rightContent {
    width: 300px;
  }
  .middleContent {
    width: 300px;
  }
  .partnerImage {
    left: 40px;
  }
  .cloudAndSaaSImage {
    left: 20px;
  }
}

@media only screen and (max-width: 900px) {
  .dashboardContent {
    flex-direction: column;
    height: auto;
  }

  .middleAndRightContainer {
    margin-top: 50px;
  }
  .leftContent {
    border-right: none;
  }
  .rightContent {
    border-left: none;
  }
  .partnerImage {
    left: 35px;
  }
  .cloudAndSaaSImage {
    left: 20px;
  }
}
</style>
