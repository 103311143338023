<template>
  <div class="rootContainer">
    <img src="../assets/mittelDeutsche_header_banner2.svg" width="350" height="70">
    <div class="formContainer">
      <a-form @submit.prevent="login" class="login">
        <a-form-item class="loginInput">
          <a-input
            id="username"
            v-model:value="username"
            type="text"
            size="large"
            autofocus
            placeholder="Username"
            required>
            <template #prefix>
              <UserOutlined :style="{color: '#B5B5B5'}"/>
            </template>
          </a-input>
        </a-form-item>
        <a-form-item class="loginInput">
          <a-input-password
            v-model:value="password"
            type="password"
            size="large"
            id="password"
            placeholder="Password"
            required>
            <template #prefix>
              <LockOutlined :style="{color: '#B5B5B5'}"/>
            </template>
          </a-input-password>
        </a-form-item>
        <a-form-item>
          <a-input
            class="inputField"
            v-model:value="domain"
            type="text"
            size="large"
            id="domain"
            placeholder="Domain"
            >
            <template #prefix>
              <BlockOutlined :style="{color: '#B5B5B5'}" />
            </template>
          </a-input>
        </a-form-item>
        <a-form-item>
          <a-button class="submitBtn" type="primary" html-type="submit">Login</a-button>
        </a-form-item>
        <div>
          <AGBs :showAgbModal="showAgbModal" @closeModal="closeAgbModal()" @agbSubmit="handleAgbSubmit()"/>
          <a-modal
            id = "qrCode"
            :title="'Der Sicherheitscode wurde an Ihre E-Mail-Addresse gesendet'"
            v-model:open="codeModal"
            :closable="true"
            centered>
            <template #footer>
              <a-button style="float: left;" key="resend" @click="sendSecretKey()">Neu senden</a-button>
              <a-button key="cancel" @click="codeModal=false">Abbrechen</a-button>
              <a-button key="submit" type="primary"  @click="SubmitCode">Ok</a-button>
            </template>
            <a-form :ref="formRefCode" :model="formCode" @finish="SubmitCode" layout="vertical">
              <a-form-item ref="inputCode" name="inputCode" :label="'Code eingeben'">
                <a-input
                  required
                  autofocus
                  autocomplete="false"
                  placeholder="Code"
                  v-model:value="formCode.inputCode"/>
              </a-form-item>
            </a-form>
          </a-modal>
        </div>
      </a-form>
    </div>
  </div>
</template>

<script>
  import logapi from '@/apis/logapis'
  import AGBs from '@/views/AGB.vue'
  import { VueElement, reactive, ref, toRaw} from 'vue'
  import { UserOutlined, LockOutlined, BlockOutlined } from '@ant-design/icons-vue'
  import notification from 'ant-design-vue/es/notification'

  export default {
    components: {
      VueElement,
      UserOutlined,
      LockOutlined,
      BlockOutlined,
      AGBs
    },
    data() {
      return {
        codeModal: false,
        username: "",
        password: "",
        inputCode: "",
        domain:"",
        src: null,
        passKey: "",
        optVerify: "",
        getValidated: false,
        qrEnabled: "",
        useruuid: "",
        accountuuid:"",
        showAgbModal:false
      };

    },
    created () {
    this.initForm()
  },
    methods: {
      initForm () {
      this.formRefCode = ref()
      this.formCode = reactive({})
    },
    login() {
      let params = new FormData()
      params.append('username', this.username)
      params.append('password', this.password)
      params.append('domain', this.domain)
      logapi.login(params).then( async response => {
        if (response.request.status === 200 ) {
          if(response.data.user['role'] === 'Admin'){
            this.$store.commit("setAccountId", response.data.user['account'])
            this.$store.commit("setStatusCloudUI",true)
            this.$store.commit("setAuthentification", true)
            this.$router.push({ path: '/globalLayout'})
          } else{
            this.$store.commit("setAccountId", response.data.user['account'])
            this.$store.commit("setEmail", response.data.user['email'])
            this.accountuuid = response.data.user['account']
            this.useruuid = response.data.user['uuid']
            this.optVerify = response.data.user['otp_verified']
            this.qrEnabled = response.data.user['otp_enabled']
            this.$store.commit("setStatusCloudUI",false)
            this.$store.commit("setAuthentification", false)

            let agbParams = new FormData()
            agbParams.append('accountuuid', response.data.user['account'])

            await logapi.getAgbStatus(agbParams).then(response => {
            if (response.data === true) {
              this.showAgbModal = true
            } else {
              if (this.optVerify === false){
                this.generateOTP()
                this.codeModal = true
              }else if(this.qrEnabled === true){
                this.sendSecretKey()
                this.codeModal = true
              }
            }
            }).catch(error => {
              if (error.response.data == "Fehler beim Übergeben der Zugangsdaten") {
                this.$notification.error({
                  message: 'Fehler',
                  description: 'Fehler beim Übergeben der Zugangsdaten, bitte versuchen Sie es erneut. Falls der Fehler besteht, melden Sie sich bitte bei unserem Kundensupport.'
                })
              } else if (error.response.data == "Account konnte nicht zugewiesen werden"){
                this.$notification.error({
                  message: 'Fehler',
                  description: 'Account konnte nicht zugewiesen werden, bitte versuchen Sie es erneut. Falls der Fehler besteht, melden Sie sich bitte bei unserem Kundensupport.'
                })
              } else {
                this.$notification.error({
                  message: 'Fehler',
                  description: 'Fehler beim Laden der AGBs, bitte versuchen Sie es erneut. Falls der Fehler besteht, melden Sie sich bitte bei unserem Kundensupport.'
                })
              }
              
            })
           
          }
          this.$store.commit("setRole", response.data.user['role'])
        } 
      }).catch(error => {
        if(error.response.status === 400){
          alert(error.response.data.user['message'])
        }
      })
    },
    generateOTP (){
      logapi.generateCode({ 'account_uuid': this.useruuid,
        'email':this.$store.getters.getEmail,'account':this.accountuuid}
      ).then(response => {
        if (response.status === 200 ) {
          this.passKey  = response.data.otp['base32']
          //this.getQrCode()
          this.sendSecretKey()
        }
      }).catch(error => {
        console.log(error.response)
      })
    },
    verifyOTP (data){
      logapi.verifyOtp({'account_uuid': this.useruuid,
        'token': data,'account':this.accountuuid}).then(response => {
        if (response.status === 200 ) {
          this.optVerify = response.data.otp_verified
        }
      }).catch(error => {
        if(error.response.status === 400){
          alert(error.response.data.message)
        }
      })
    },
    validateOTP (data){
     logapi.validateOtp({'account_uuid': this.useruuid,
        'token': data,'account':this.accountuuid}).then(response => {
        if (response.status === 200 ) {
          this.$store.commit("setOtpValidated",response.data.otp_valid)
          this.getValidated = response.data.otp_valid
          if(this.getValidated === true){
            this.$store.commit("setAuthentification", true)

            this.$router.push({ path: '/globalLayout'})
          }
        }else {
          alert('Wrong secret code, try again.')
          this.getValidated = false
          this.$store.commit("setAuthentification", false)
        }
      }).catch(error => {
       if(error.response.status === 400){
         alert(error.response.data.message)
       }
      })
    },
    sendSecretKey(){
      logapi.sendKey({'account_uuid': this.useruuid,
          'account':this.accountuuid}).then(response =>{
        if (response.status === 200 ){
          console.log("Email sent")
        }
      }).catch(error => {
        if(error.response.status === 400){
          alert(error.response.data.message)
        }
      })
    },
    SubmitCode () {
      this.formRefCode.value.validate().then(async () => {
        const values = toRaw(this.formCode)
        if(this.optVerify === false){
          this.verifyOTP(values.inputCode)
        }
        if(this.getValidated === false){
          this.validateOTP(values.inputCode)
        }
      })
    },
    closeAgbModal () {
      this.showAgbModal = false
    },
    handleAgbSubmit () {
      this.showAgbModal = false
      if (this.optVerify === false){
        this.generateOTP()
        this.codeModal = true
      }else if(this.qrEnabled === true){
        this.sendSecretKey()
        this.codeModal = true
      }
    }
  },
}
</script>

<style scoped>
  .rootContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 100vh;
    top: 0;
    left: 0;
    background-image: url('../assets/Group-3581.svg');
    background-position: center;
    background-color: rgba(181,181,181,0.05)
  }
  .formContainer {
    display: flex;
    justify-content: center;
    border-radius: 10px;
    background-color: rgba(181,181,181,0.2);
    height: 300px;
    align-items: center;
    width: 400px;
    margin-top: 50px;
  }
  .submitBtn {
    font-weight: bold;
    width: 250px;
  }
</style>
