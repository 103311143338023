<template>
  <div v-if="this.maintenanceData !== null && this.maintenanceData.length > 1" class="scrolling-text-container">
    <div class="scrolling-text-inner" id="scrollBar" @mouseover="pauseAnimation()" @mouseleave="runAnimation()">
        <div class="scrolling-text">
            <div v-for="maintenance in maintenanceData" :class="['scrolling-text-item',maintenance.Type]"><span class="scrolling-text-item-text" @click="goToSpecificNews(maintenance)">{{maintenance.EffectiveDate +":&nbsp"+maintenance.Title}}</span></div>
        </div>
        <div class="scrolling-text">
            <div v-for="maintenance in maintenanceData" :class="['scrolling-text-item',maintenance.Type]"><span class="scrolling-text-item-text" @click="goToSpecificNews(maintenance)">{{maintenance.EffectiveDate +":&nbsp"+maintenance.Title}}</span></div>
        </div>
        <div class="scrolling-text">
            <div v-for="maintenance in maintenanceData" :class="['scrolling-text-item',maintenance.Type]"><span class="scrolling-text-item-text" @click="goToSpecificNews(maintenance)">{{maintenance.EffectiveDate +":&nbsp"+maintenance.Title}}</span></div>
        </div>
        <div class="scrolling-text">
            <div v-for="maintenance in maintenanceData" :class="['scrolling-text-item',maintenance.Type]"><span class="scrolling-text-item-text" @click="goToSpecificNews(maintenance)">{{maintenance.EffectiveDate +":&nbsp"+maintenance.Title}}</span></div>
        </div>
        <div class="scrolling-text">
            <div v-for="maintenance in maintenanceData" :class="['scrolling-text-item',maintenance.Type]"><span class="scrolling-text-item-text" @click="goToSpecificNews(maintenance)">{{maintenance.EffectiveDate +":&nbsp"+maintenance.Title}}</span></div>
        </div>
        <div class="scrolling-text">
            <div v-for="maintenance in maintenanceData" :class="['scrolling-text-item',maintenance.Type]"><span class="scrolling-text-item-text" @click="goToSpecificNews(maintenance)">{{maintenance.EffectiveDate +":&nbsp"+maintenance.Title}}</span></div>
        </div>
        <div class="scrolling-text">
            <div v-for="maintenance in maintenanceData" :class="['scrolling-text-item',maintenance.Type]"><span class="scrolling-text-item-text" @click="goToSpecificNews(maintenance)">{{maintenance.EffectiveDate +":&nbsp"+maintenance.Title}}</span></div>
        </div>
        <div class="scrolling-text">
            <div v-for="maintenance in maintenanceData" :class="['scrolling-text-item',maintenance.Type]"><span class="scrolling-text-item-text" @click="goToSpecificNews(maintenance)">{{maintenance.EffectiveDate +":&nbsp"+maintenance.Title}}</span></div>
        </div>
        <div class="scrolling-text">
            <div v-for="maintenance in maintenanceData" :class="['scrolling-text-item',maintenance.Type]"><span class="scrolling-text-item-text" @click="goToSpecificNews(maintenance)">{{maintenance.EffectiveDate +":&nbsp"+maintenance.Title}}</span></div>
        </div>
        <div class="scrolling-text">
            <div v-for="maintenance in maintenanceData" :class="['scrolling-text-item',maintenance.Type]"><span class="scrolling-text-item-text" @click="goToSpecificNews(maintenance)">{{maintenance.EffectiveDate +":&nbsp"+maintenance.Title}}</span></div>
        </div>
        <div class="scrolling-text">
            <div v-for="maintenance in maintenanceData" :class="['scrolling-text-item',maintenance.Type]"><span class="scrolling-text-item-text" @click="goToSpecificNews(maintenance)">{{maintenance.EffectiveDate +":&nbsp"+maintenance.Title}}</span></div>
        </div>
    </div>
  </div>
  <div v-else-if="this.maintenanceData !== null && this.maintenanceData.length == 1" class="soloMaintenance">
    <span class="maintenanceText" @click="goToSpecificNews(this.maintenanceData[0])">{{ this.maintenanceData[0].EffectiveDate +":&nbsp"+ this.maintenanceData[0].Title }}</span>
  </div>
  <div style="width: 100%; height: calc( 100vh - (100px + 50px)); overflow-y: auto; background-image:linear-gradient(180deg, #02010100 23%, #B9B9B9 100%)">
    <router-view @get-maintenances="getMaintenances" :clicked="clicked"/>
  </div>
</template>

<script>
import notification from 'ant-design-vue'
import logapi from '@/apis/logapis'

export default {
  name: "Content",
  components: {
    notification
  },
  data () {
    return {
      maintenanceData: [],
      clicked: 1,
      scrollingElements: [],
    }
  },
  mounted () {
    this.getMaintenances()
  },
  updated () {
    this.setScrollDuration()
  },
  methods: {
    async getMaintenances () {
      await logapi.getMaintenances().then(response => {
        this.maintenanceData = response.data
        if (response.data !== null) {
          for (let i = 0; i < this.maintenanceData.length; i++) {
            this.maintenanceData[i].EffectiveDate = new Date(Date.parse(this.maintenanceData[i].EffectiveDate)).toLocaleDateString() 
          }
        }
      }).catch(error => {
        console.log(error)
        this.$notification.error({
          message: 'Fehler',
          description: error.response.data
        })
      })
    },
   goToSpecificNews (record) {
      this.$store.commit("setHighlightedNews", record)
      if (this.clicked === 2) {
        this.clicked = 1
      } else {
        this.clicked = 2
      }

      if (this.$route.path !== '/news') {
        this.$router.push({ path: '/news'})
      }
    },
     setScrollDuration () {
      var scrollingElements = document.getElementsByClassName("scrolling-text")
      for (let i = 0; i < scrollingElements.length; i++) {
        const animationString = "scroll-right-7eab81f9 " + this.maintenanceData.length * 5 +"s linear infinite"
        scrollingElements[i].style.animation = animationString
      }
    },
    pauseAnimation () {
      var scrollingElements = document.getElementsByClassName("scrolling-text")
      for (let i = 0; i < scrollingElements.length; i++) {
        scrollingElements[i].style.animationPlayState = "paused"
      }
    },
    runAnimation () {
      var scrollingElements = document.getElementsByClassName("scrolling-text")
      for (let i = 0; i < scrollingElements.length; i++) {
        scrollingElements[i].style.animationPlayState = "running"
      }
    }
  }
}
    
</script>

<style scoped>
.scrolling-text-container {
    overflow: hidden;
    font-weight: bold;
    height: 50px;
    display: flex; 
    align-items: center; 
    justify-content: center; 
    position: sticky;
    background-color: #4d4d4d;
}

.scrolling-text-inner {
    display: flex;
    white-space: nowrap;
}

.scrolling-text {
    display: flex;
    height: 50px;
    align-items: center;
}

.scrolling-text-item {
    padding: 0 100px;
    color: white;
    height: 50px;
    display: flex;
    align-items: center;
}
.scrolling-text-item-text:hover {
    cursor: pointer;
}

.Neuerung{
  background-color: #4d4d4d;
}

.Alarm {
  background-color: #4d4d4d;
  color:#ff5553;
  font-size: 20px;
}

.Wartung {
  background-color: #4d4d4d;
}

.maintenanceText {
  color: white;
   font-size: 20px;
}

.maintenanceText:hover {
  cursor: pointer;
}

.soloMaintenance {
  height: 50px;
  background-color:#4d4d4d;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  position: sticky
}

@keyframes scroll-right {
    0% {
        transform: translateX(0%);
    }

    100% {
        transform: translateX(-100%);
    }
}
</style>