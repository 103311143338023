<template>
    <div>
      <a-modal
        :visible="showAgbModal"
        title="Allgemeine Vertragsbedingungen"
        centered
        @cancel="closeModal()"
        width="48vw">
        <div class="agbContent">
          <h2>Allgemeine Vertragsbedingungen für die Vermietung von Rechenzentrum- Kapazitäten sowie für Cloud Service Verträge</h2>
          <hr color="#d02c2a" >
          <p>{{ 'Die nachfolgenden Allgemeinen Vertragsbedingungen für die Vermietung von Rechenzentrum- Kapazitäten sowie für Cloud Service '
            + 'Verträge (im Folgenden: AGB Cloud) gelten für alle Verträge der mitteldeutsche IT GmbH über die Bereitstellung von Rechenzentrum- '
            + 'Kapazitäten, insbesondere in Form der Cloud-Serviceleistungen „Infrastructure as a Service“ (im Folgenden: IaaS), „Platform as a '
            + 'service“ (im Folgenden: PaaS) sowie „Software as a Service (Im Folgenden: SaaS). Soweit nicht ausdrücklich etwas anderes vereinbart '
            + 'wird, werden allgemeine oder besondere Geschäftsbedingungen des Kunden nicht Vertragsbestandteil.' }}
          </p>
          <br>
          <h2>I. Vertragsabschluss und -bestandteile; Erfüllungsort</h2>
          <hr color="#d02c2a" >
          <p><span>1.</span>{{ 'Der Vertrag zwischen mitteldeutsche IT GmbH und dem Kunden kommt durch Annahmeerklärung der mitteldeutsche IT GmbH zustande. '
            + 'Annahmeerklärung in diesem Sinne ist auch der Beginn der Leistungserbringung (Übergabe der Zugangsdaten zum Cloudportal/Übergabe '
            + 'von Cloudservices, z.B. virtuelle Desktops/Instanzen/Server) durch die mitteldeutsche IT GmbH aufgrund der Bestellung bzw. des Angebots des Kunden. ' }}
          </p>
          <p><span>2.</span>{{ 'Bestandteil des Vertrags zwischen der mitteldeutsche IT GmbH und dem Kunden sind die zwischen diesen Vertragsparteien '
            + 'ausdrücklich vereinbarten Leistungen und Pflichten sowie die gegenständlichen Allgemeinen Vertragsbedingungen. Bei Widersprüchen '
            + 'gehen die ausdrücklich vereinbarten Leistungen und Pflichten den Regelungen dieser Allgemeinen Vertragsbedingungen vor. ' }}
          </p>
          <p><span>3.</span>{{ 'Erfüllungsort ist der Sitz der mitteldeutsche IT GmbH.' }}</p>
          <br>
          <h2>II. Leistungspflichten der mitteldeutsche IT GmbH</h2>
          <hr color="#d02c2a" >
          <p><span>1.</span>{{ 'Der Umfang der Hauptleistungspflichten der mitteldeutsche IT GmbH ergibt sich aus dem Angebot sowie der zugehörigen Auftragsbestätigung bzw. aus '
            + 'den gemäß Kundenaccount bestellten Leistungen.' }}
          </p>
          <p>{{ 'Die mitteldeutsche IT GmbH stellt die jeweiligen notwendigen Infrastrukturen sowie den Zugriff per '
            + 'Internet zur Verfügung. Schnittstelle für die Leistungserbringung der mitteldeutsche IT GmbH sind die Cloud Services innerhalb der Rechenzentren der '
            + 'mitteldeutsche IT GmbH.' }}
          </p>
          <p>{{ 'Die Anbindung des Kunden an das Internet, die Aufrechterhaltung der Netzverbindung sowie die Beschaffenheit, Bereitstellung und '
            + 'Geeignetheit der auf Seiten des Kunden erforderlichen Hard- und Software ist ausschließlich Sache des Kunden und außerhalb der Leistungspflichten der'
            + ' mitteldeutsche IT GmbH. ' }}
          </p>
          <p>{{ 'Die mitteldeutsche IT GmbH ist nicht verpflichtet, dem Kunden ein Benutzerhandbuch für die in Anspruch genommene Dienste bereit zu stellen.' }}</p>
          <br>
          <p><span>2.</span>{{ 'Soweit die mitteldeutsche IT GmbH dem Kunden feste IP-Adressen zur Verfügung stellt, behält sich die mitteldeutsche IT GmbH eine Neuzuordnung vor, sofern '
            + 'dies aus technischen oder rechtlichen Gründen erforderlich ist.' }}
          </p>
          <br>
          <p><span>3.</span>{{ 'Cloud-Dienste der mitteldeutsche IT GmbH sind im Jahresmittel zu 99 %, bezogen auf das Geschäftsbeziehungsjahr, verfügbar (Bsp. Vertragsschluss am '
            + '01.04.2022: Geschäftsbeziehungsjahr endet am 31.03.2023).' }}
          </p>
          <br>
          <p><span>4.</span>{{ 'Als Ausfallzeiten, betreffend die Verfügbarkeit gemäß vorgehender Ziff. 3, gelten nicht:' }}</p>
          <p style="margin-left: 30px;">{{ ' \u2022 Ausfallzeiten, die durch den Kunden oder von ihm verwendete Software oder vom Kunden falsch installierte Software verursacht sind,' }}</p>
          <p style="margin-left: 30px;">{{ ' \u2022 Ausfallzeiten, verursacht durch Hersteller von Software in der eingesetzten Infrastruktur,' }}</p>
          <p style="margin-left: 30px;">{{ ' \u2022 Ausfallzeiten durch vom Kunden eingeschleuste Schadcodes, z.B. infizierte Software oder Daten,' }}</p>
          <p style="margin-left: 30px;">{{ ' \u2022 Ausfallzeiten, deren Gründe außerhalb des Einflussbereichs der mitteldeutsche IT GmbH liegen, zum Beispiel externe'
            + ' DNS-Probleme, Angriffe auf Netz- und Mailsysteme, Ausfälle von Teilen des Internet, höhere Gewalt (z. B. Naturkatastrophen, Streik, kriegerische Ereignisse),' }}
          </p>
          <p style="margin-left: 30px;">{{ ' \u2022 Ausfallzeiten wegen planmäßiger Wartungsarbeiten; über planmäßige Wartungsarbeiten wird der Kunde vorab informiert.' }}</p>
          <br>
          <p><span>5.</span>{{ 'Die mitteldeutsche IT GmbH führt an ihren Systemen (Hard- und Software) zur Sicherheit des Netzbetriebes, zur Aufrechterhaltung der Netzintegrität'
            + ' sowie der Interoperabilität der Dienste und zur Aufrechterhaltung des Datenschutzes regelmäßig Wartungsarbeiten durch, die – sofern möglich – in'
            + ' nutzungsarmen Zeiten durchgeführt werden. Hierdurch kann es zu partiellen oder vollständigen Leistungseinschränkungen kommen. Die mitteldeutsche IT GmbH'
            + ' wird die Kunden über Art, Ausmaß sowie Dauer der Beeinträchtigung unterrichten, sofern dies möglich ist.' }}
          </p>
          <p>{{ 'Einschränkungen dieser Art sind keine Ausfallzeiten im Sinne der vorgehenden Ziff. 3.' }}</p>
          <br>
          <h2>III. Allgemeine Mitwirkungspflicht des Kunden</h2>
          <hr color="#d02c2a" >
          <p><span>1.</span>{{ 'Der Kunde ist verpflichtet, diejenigen erforderlichen und angemessenen Handlungen vorzunehmen, die notwendig sind, um die Dienste der'
            + ' mitteldeutsche IT GmbH in Anspruch nehmen zu können. Dies gilt insbesondere auch, wenn zum Betrieb des Rechenzentrums bzw. der Cloud eine Mitwirkung des Kunden'
            + ' zur Aufrechterhaltung der Dienste erforderlich ist (z. B. durch erneute Eingabe von Zugangsdaten oder einfache Umstellungen der Systeme durch den Kunden).' }}
          </p>
          <p><span>2.</span>{{ 'Soweit nicht ausdrücklich etwas anderes vereinbart ist, ist es dem Kunden gestattet, von beliebigen Rechnern auf die Infrastruktur der'
            + ' mitteldeutsche IT GmbH im vertraglich eingeräumten Umfang Zugriff zu nehmen.' }}
          </p>
          <p><span>3.</span>{{ 'Der Kunde ist dafür verantwortlich, dass die von ihm eingesetzte Hard- und Software einschließlich Arbeitsplatzrechnern, Routern sowie'
            + ' Kommunikationsmitteln zur Nutzung der Leistungen der mitteldeutsche IT GmbH technisch geeignet ist, insbesondere den Anforderungen, die dem Kunden mittels'
            + ' Angebots- und Auftragsbestätigung der mitteldeutsche IT GmbH bekannt gegeben wurden, entsprechen. Der Kunde trägt ferner dafür Sorge, dass die'
            + ' Inanspruchnahme der Dienste der mitteldeutsche IT GmbH durch durch ihn autorisierte Personen erfolgt, die mit der Bedienung vertraut sind. Die'
            + ' Konfiguration des IT-Systems des Kunden, insbesondere die Anpassung an die Dienste der mitteldeutsche IT GmbH einschließlich geeigneter (neuer) Soft- und'
            + ' Hardware erfolgt durch den Kunden auf dessen eigene Kosten.' }}
          </p>
          <p><span>4.</span>{{ 'Der Kunde ist verpflichtet, seine Internetseite so zu gestalten, dass eine übermäßige Belastung des Servers der mitteldeutsche IT GmbH (z. B. durch'
            + ' CGI-Skripte), insb. eine übermäßig hohe Rechenleistung bzw. übermäßige Belastung des Arbeitsspeichers, vermieden wird. Die mitteldeutsche IT GmbH ist'
            + ' berechtigt, Seiten des Kunden, die diese Anforderungen nicht entsprechen, vom Zugriff durch den Kunden oder Dritte auszuschließen; über eine solche'
            + ' Maßnahme wird der Kunde unverzüglich informiert. Der Ausschluss vom Zugriff endet, sobald der Kunde plausibel darlegt, dass eine Umgestaltung seiner'
            + ' Seiten erfolgte und eine übermäßige Belastung des Servers nicht mehr zu befürchten ist.' }}
          </p>
          <p><span>5.</span>{{ 'Der Kunde nutzt die Leistungen der mitteldeutsche IT GmbH nach Art um Umfang entsprechend eines ordentlichen Kaufmanns unter Beachtung der guten'
            + ' Sitten. Dem Kunden ist bewusst, dass durch übertriebene Inanspruchnahme der Dienste der mitteldeutsche IT GmbH die Funktionsfähigkeit sowie Sicherheit des'
            + ' Gesamtsystems sowie des Netzes der mitteldeutsche IT GmbH beeinträchtigt werden kann. Der Kunde trägt durch sein verantwortungsvolles Nutzerverhalten zur'
            + ' Stabilität sowie Sicherheit des Gesamtsystems und des Netzes bei.' }}
          </p>
          <p><span>6.</span>{{ 'Wird die Sicherheit, Integrität oder Verfügbarkeit der Dienste der mitteldeutsche IT GmbH durch einen Kunden - bewusst oder unbewusst - gefährdet'
            + ' oder bestehen Anhaltspunkte hierfür, dass eine Gefährdung bevorstehen könnte, so ist die mitteldeutsche IT GmbH berechtigt, den Zugang des Kunden zu den'
            + ' Diensten der mitteldeutsche IT GmbH einzuschränken oder zu sperren. Die Sperrung bzw. Einschränkung des Zugangs aus den vorgehenden Gründen stellt keine'
            + ' Ausfallzeit im Sinne der obigen Regelung der Ziff. II, dort Nr. 3 und 4, dar.' }}
          </p>
          <p><span>7.</span>{{ 'Dem Kunden ist es untersagt, über die Dienste der mitteldeutsche IT GmbH Spam-Mails zu versenden bzw. denial of service-Attacken auszuführen; der'
            + ' Kunde stellt sicher, dass auch Dritten die Vornahme solcher Handlungen mittels des Zugangs des Kunden zu den Diensten der mitteldeutsche IT GmbH nicht'
            + ' ermöglicht wird.' }}
          </p>
          <p><span>8.</span>{{ 'Dem Kunden ist es untersagt, die Dienste der mitteldeutsche IT GmbH missbräuchlich zu nutzen. Missbräuchlich ist insb., wenn der Kunde gesetzlichen'
            + ' Regelungen zuwider handelt, insb. Leistungen oder Informationen anbietet, die gegen strafrechtliche Bestimmungen, insb. des Jugendschutzes, verstoßen'
            + ' oder gewaltverherrlichend sind. Der Kunde beachtet Urheber-, Marken- und andere gewerbliche Schutzrechte Dritter und unterlässt jedwede Verletzung.'
            + ' Dies gilt auch hinsichtlich Persönlichkeitsrechten Dritter. Der Kunde trägt dafür Sorge, dass auch keine Dritten über den ihm gewährten Zugang in der'
            + ' vorgehend beschriebenen Art handeln können. Wird die mitteldeutsche IT GmbH durch Dritte wegen der vorgehend beschriebenen Verhaltensweisen des Kunden in'
            + ' Anspruch genommen, so stellt der Kunde die mitteldeutsche IT GmbH von einer solchen Inanspruchnahme frei.' }}
          </p>
          <p><span>9.</span>{{ 'Der Kunde trägt dafür Sorge, dass sein Onlinezugang bzw. das ihm eingeräumte Recht auf Zugang zu den Cloud-Diensten der mitteldeutsche IT GmbH'
            + ' umfassend geschützt wird, insbesondere Passwörter für den Zugang zu den Diensten streng geheim zu halten sind. Der Kunde ist verpflichtet, die'
            + ' mitteldeutsche IT GmbH unverzüglich darüber zu informieren, sobald ihm bekannt wird, dass unbefugte Dritte im Besitz des Passworts sind. Das gleiche gilt, wenn'
            + ' der Kunde Anlass zur Sorge haben muss, dass unbefugte Dritte sich Zugang zu seiner Hard- und Software verschafft haben oder dies versuchen.' }}
          </p>
          <p><span>10.</span>{{ 'Verwenden Dritte ein Passwort des Kunden, das diesem zur Identifizierung seiner Person gegenüber der mitteldeutsche IT GmbH zur Abgabe'
            + ' vertragsrelevanter Erklärung dient, so ist der Kunde zur Vergütung hierauf basierender Leistungen der mitteldeutsche IT GmbH, die diese gegenüber dem'
            + ' Dritten erbringt, verpflichtet. Dies gilt nicht, sofern den Kunden kein Verschulden, betreffend den Verlust/Missbrauch der Passwörter, trifft.' }}
          </p>
          <p><span>11.</span>{{ 'Soweit die mitteldeutsche IT GmbH dem Kunden Software zur internetbasierten Nutzung auf ihrer IT-Infrastruktur oder bei einem externen'
            + ' IT-Dienstleister zum Gebrauch zur Verfügung stellt, räumt mitteldeutsche IT GmbH, sofern nichts anderes vereinbart ist, dem Kunden ein zeitlich auf die'
            + ' Laufzeit der zugehörigen Hauptleistung beschränktes einfaches Nutzungsrecht für eigene und fremde Software, Programme oder Skripten sowie Handbücher,'
            + ' Einrichtungs- und Schulungsunterlagen im Umfang ihrer Auftragsbestätigung ein. Eigentum oder ein dauerhaftes Recht zur Nutzung erwirbt der Kunde'
            + ' insoweit nicht.' }}
          </p>
          <p><span>12.</span>{{ 'Ein Recht zur Vervielfältigung von durch mitteldeutsche IT GmbH überlassener Software wird nicht eingeräumt. Ein Recht zur Weitergabe eingeräumter'
            + ' Nutzungsrechte besteht nicht. Untersagt ist ferner die Bearbeitung/Veränderung überlassener Software. Unzulässig ist es ferner, Kennzeichen und'
            + ' Hinweise (auch Copyright, Trademarks, Urheberrechte etc.) zu entfernen, zu verändern oder unkenntlich zu machen. Nicht gestattet ist es, überlassene'
            + ' Software oder Softwarebestandteile nachzukonstruieren, zu dekompilieren sowie zu deassemblieren, sofern dies nicht ausdrücklich gestattet ist.' }}
          </p>
          <br>
          <h2>IV. Leistungsinhalt "Infrastructure as a Service (IaaS)"; Mitwirkungspflichten des Kunden</h2>
          <hr color="#d02c2a">
          <p><span>1.</span>{{ 'Erbringt die mitteldeutsche IT GmbH Infrastructure as a Service (kurz „IaaS“) , so ist der Kunde berechtigt, über das Internet auf die vereinbarten'
            + ' Dienste der mitteldeutsche IT GmbH zuzugreifen, insbesondere virtuelle Server, virtuelle Desktops, virtuelle Netzwerke sowie Software (z.B. Betriebssysteme)'
            + ' gemäß den vertraglich vereinbarten Volumina zu nutzen. Hierbei obliegt dem Kunden die alleinige Verwaltung aller Recheninstanzen; der Kunde ist'
            + ' berechtigt, eigene Daten und eigene Programme auf der Infrastruktur zu nutzen.' }}
          </p>
          <p><span>2.</span>{{ 'Die Nutzung erfolgt durch den Kunden in eigener Verantwortung und zu eigenen Zwecken. Dem Kunden ist es nicht gestattet, die Infrastruktur'
            + ' mittels Software/Daten für gesetzeswidrige Zwecke zu nutzen, insbesondere ist es dem Kunden untersagt, jugendgefährdende oder gewaltverherrlichende'
            + ' Inhalte zu speichern, zu bearbeiten, zu verbreiten/teilen oder in sonstiger Weise zu nutzen oder Dritten zugänglich zu machen. Der Kunde beachtet'
            + ' ferner insbesondere Urheberrechte Dritter und unterlässt jedwede Beeinträchtigung oder Verletzung dieser. Der Kunde sorgt dafür, dass die von ihm'
            + ' verwendeten eigenen Programme zu seinen Gunsten hinreichend lizensiert sind. Soweit der Kunde Software zur Nutzung seitens der mitteldeutsche IT GmbH'
            + ' bezieht, ist er verpflichtet, die Software ausschließlich im von ihm bestellten Umfang (z.B. Anzahl der Arbeitsplätze gemäß Bestellung) zu nutzen; es'
            + ' ist dem Kunden untersagt, in diese Software einzugreifen bzw. Schutzrechte Dritter hieran zu verletzen.' }}
          </p>
          <p><span>3.</span>{{ 'Die mitteldeutsche IT GmbH stellt lediglich die Infrastruktur zur Verfügung. Die mitteldeutsche IT GmbH ist verpflichtet, sich jedweder Kenntnis und'
            + ' Kenntnisnahme von Daten oder Programmen des Kunden zu enthalten. Umgekehrt obliegen der mitteldeutsche IT GmbH auch keine Obhutspflichten, betreffend die'
            + ' Programme (z.B. hinreichende Lizensierung) und Daten (z.B. gesetzeswidrige Inhalte) der Kunden.' }}
          </p>
          <p><span>4.</span>{{ 'Der Kunde ist jederzeit berechtigt, entsprechend der vertraglich vereinbarten Infrastrukturvolumina die Infrastruktur zu nutzen. Schöpft der'
            + ' Kunde das Volumen nicht vollständig aus, so besteht keine Pflicht der mitteldeutsche IT GmbH, das nicht ausgeschöpfte Volumen reserviert bereit zu halten.'
            + ' Hinreichend ist es, wenn die mitteldeutsche IT GmbH dem Kunden bei Abruf des vereinbarten Volumens dieses zur Verfügung stellen kann.' }}
          </p>
          <p><span>5.</span>{{ 'Es ist alleinige Sache des Kunden, ob die von ihm bei der mitteldeutsche IT GmbH zur Nutzung bezogene Software für die Zwecke des Kunden geeignet bzw.'
            + ' kompatibel mit der durch den Kunden bezogenen virtuellen Hardwareinfrastruktur der mitteldeutsche IT GmbH (z. B. Umfang des Speicherplatzes) ist.' }}
          </p>
          <p><span>6.</span>{{ 'Liegt ein Mangel an der vom Kunden bei mitteldeutsche IT GmbH erworbenen, von Dritten hergestellten Software vor, so tritt die mitteldeutsche IT GmbH ihre'
            + ' Gewährleistungsansprüche gegen den Hersteller an den Kunden ab zur Erfüllung ihrer Gewährleistungsverpflichtung.' }}
          </p>
          <p>{{ 'Der Kunde ist selbst verpflichtet, bei der von ihm erworbenen Software für die Installation von Updates des Herstellers zu sorgen.' }}</p>
          <br>
          <h2>V. Leistungsinhalt "Platform as a Service (PaaS)"; besondere Mitwirkungspflichten des Kunden</h2>
          <hr color="#d02c2a">
          <p><span>1.</span>{{ 'Bei „Platform as a service“ (kurz „PaaS“) stellt die mitteldeutsche IT GmbH dem Kunden Verarbeitungseinheiten in ihrem Rechenzentrum zur Nutzung über'
            + ' das Internet zur Verfügung. Anders als bei „IaaS“ hat der Kunde keinen direkten Zugriff auf Recheninstanzen der mitteldeutsche IT GmbH. Der Kunde bringt'
            + ' ausschließlich seine Programmlogik in die Recheneinheit der mitteldeutsche IT GmbH ein, die die Programmierschnittstelle für den Kunden bildet. Aufgabe der'
            + ' mitteldeutsche IT GmbH ist hier die erforderliche Instanziierung der Verarbeitungseinheiten, die Organisation des Zusammenwirkens unterschiedlicher'
            + ' Komponenten und Softwareeinheiten sowie das Verteilen der zu verarbeitenden Daten.' }}
          </p>
          <br>
          <h2>VI. Leistungsinhalt "Software as a Service (SaaS)"; besondere Mitwirkungspflichten des Kunden</h2>
          <hr color="#d02c2a">
          <p><span>1.</span>{{ 'Bei „Software as a Service” (kurz: SaaS) stellt die mitteldeutsche IT GmbH dem Kunden dieses Service - bei diesem Kunden handelt es sich um'
            + ' Softwarehersteller (im Folgenden: SaaS-Kunde) - Verarbeitungseinheiten in ihrem Rechenzentrum zur Verfügung, über die der SaaS-Kunde seine Software'
            + ' gegenüber Dritten (im Folgenden: Drittkunden) zum Erwerb/zur Nutzung anbieten, verkaufen und zur zeitweisen Nutzung überlassen kann.' }}
          </p>
          <p><span>2.</span>{{ 'Der SaaS-Kunde schließt Verträge mit seinen Kunden ausschließlich im eigenen Namen. Der SaaS-Kunde vertritt die mitteldeutsche IT GmbH'
            + ' nicht. Der SaaS-Kunde handelt gegenüber seinen Kunden ausschließlich auf eigene Rechnung und eigenes Risiko. Rechtsbeziehungen zwischen der mitteldeutsche'
            + ' IT GmbH einerseits sowie den Drittkunden der SaaS-Kunden andererseits werden nicht begründet.' }}
          </p>
          <p><span>3.</span>{{ 'Der SaaS-Kunde ist verpflichtet, im Rahmen der Nutzung der Infrastruktur der mitteldeutsche IT GmbH keine Leistungen oder Programme'
            + ' gegenüber seinen Kunden anzubieten, zu veräußern oder diesen in sonstiger Weise zur Verfügung zu stellen, mit denen Schutzrechte Dritter jedweder Art'
            + ' verletzt werden, insbesondere Urheber- oder Eigentumsrechte Dritter.' }}
          </p>
          <p>{{ 'Dem SaaS-Kunden ist es insbesondere untersagt, Kennzeichen und Hinweise Dritter, z. B. Copyrights, Trademarks etc., zu entfernen, zu verändern oder'
            + ' unkenntlich zu machen, ebenso wie es untersagt ist, urheberrechtlich geschützte Software Dritter nachzukonstruieren, zu dekompilieren sowie zu'
            + ' deassemblieren, sofern dem SaaS-Kunden dies nicht durch den jeweiligen Rechteinhaber ausdrücklich gestattet ist.' }}
          </p>
          <p><span>4.</span>{{ 'Der SaaS-Kunde stellt seinen Kunden keine Programme oder Dienste zur Verfügung, die gegen ein gesetzliches Verbot verstoßen oder zur'
            + ' Begehung von Straftaten geeignet sind oder hierfür genutzt werden können. Dies gilt insbesondere auch für Verwendungsmöglichkeiten zur Gefährdung der'
            + ' Luftfahrt, zur Verwendung im Bereich der Sicherheit von militärischen Anlagen oder von Anlagen der Energiegewinnung/-versorgung, zur Begehung'
            + ' unweltrechtlicher Verstöße sowie zur Nutzung im Darknet.' }}
          </p>
          <p><span>5.</span>{{ 'Der SaaS-Kunde ist verpflichtet, es zu unterlassen, Kunden über die Infrastruktur der mitteldeutsche IT GmbH mit Diensten und Programmen zu'
            + ' versorgen, wenn er davon Kenntnis erlangt, dass sein Kunde diese Dienste und/oder Software zur Begehung strafbarer Handlungen jedweder Art, insbesondere'
            + ' aber auch zu Einwirkungen gemäß der vorgehenden Ziff. 4 einsetzt.' }}
          </p>
          <p><span>6.</span>{{ 'Wenn und soweit die mitteldeutsche IT GmbH durch Dritte wegen des Verhaltens des SaaS-Kunden oder den Drittkunden in Anspruch genommen wird,'
            + ' so ist der SaaS-Kunde verpflichtet, die mitteldeutsche IT GmbH von jedweder Inanspruchnahme freizustellen.' }}
          </p>
          <p><span>7.</span>{{ 'Der SaaS-Kunde ist auch Kunde im Sinne der vor- und nachgehenden Vorschriften dieser Allgemeinen Vertragsbedingungen; die Formulierung'
            + ' „SaaS-Kunde“ dient in diesem Abschnitt lediglich dazu, über die anderen Vorschriften hinaus besondere Pflichten des SaaS-Kunden festzulegen.' }}
          </p>
          <br>
          <h2>VII. Support; Service Level Agreement</h2>
          <hr color="#d02c2a">
          <p><span>1.</span>{{ 'Die mitteldeutsche IT GmbH erbringt für ihre Cloud-Services den Support gemäß der zwischen den Vertragsparteien getroffenen Vereinbarung.' }}
          </p>
          <p><span>2.</span>{{ 'Die mitteldeutsche IT GmbH richtet während ihrer Betriebszeit (werktäglich 08:00 Uhr bis 17:00 Uhr) einen Helpdesk ein, der mit fachlich'
            + ' qualifizierten und erfahrenen Mitarbeitern besetzt ist. Außerhalb der Betriebszeiten der mitteldeutsche IT GmbH besteht eine Rufbereitschaft der mitteldeutsche'
            + ' IT GmbH nur dann, wenn dies ausdrücklich vereinbart ist.' }}
          </p>
          <p><span>3.</span>{{ 'Auftretende Funktionsbeeinträchtigungen werden wie folgt kategorisiert:' }}</p>
          <p style="margin-left: 30px;">{{ 'a) Fehlerklasse 1: Gravierende Fehler, die eine wirtschaftlich sinnvolle Nutzung wesentlicher Teile der Leistung verhindern'
            + '(„alles steht, nichts geht“). Der Benutzer ist nicht arbeitsfähig.' }}
          </p>
          <p style="margin-left: 30px;">{{ 'b) Fehlerklasse 2: Funktionsunterbrechungen, die die Anwendung von wesentlichen Teilen der Leistung für eine wirtschaftlich'
            + ' sinnvolle Nutzung stark einschränken. Der Benutzer ist in seiner Arbeit eingeschränkt.' }}
          </p>
          <p style="margin-left: 30px;">{{ 'c) Fehlerklasse 3: Einschränkung der Funktionsfähigkeit; bis auf Ausnahmen ist die Anwendung wirtschaftlich sinnvoll einsetzbar.' }}
          </p>
          <p style="margin-left: 30px;">{{ 'd) Fehlerklasse 4: Schwächen der Software, die die Anwendung nicht einschränken. Die Beeinträchtigung ist so gering, dass'
            + 'mit dem Programm im Wesentlichen gearbeitet werden kann und die Arbeitsergebnisse brauchbar sind.' }}
          </p>
          <p><span>4.</span>{{ 'Erfolgt vom Kunden eine Fehlermeldung während der Betriebszeit bzw. – wenn zusätzlich vereinbart – mittels telefonischer Rufbereitschaft'
            + ', so ordnet die mitteldeutsche IT GmbH die Fehlermeldung nach billigem Ermessen einer Fehlerklasse zu. Der Kunde ist berechtigt, eine Zuordnung zu einer'
            + ' Fehlerklasse vorzunehmen. Weicht die Zuordnung der mitteldeutsche IT GmbH zu einer Fehlerklasse um nicht mehr als eine Fehlerklasse vom Vorschlag des Kunden'
            + ' zur Zuordnung zu einer Fehlerklasse ab, so gilt die Einordnung der durch die mitteldeutsche IT GmbH vorgenommenen Fehlerklasse als einvernehmlich; unberührt'
            + ' hiervon bleibt das Recht des Kunden, den Beweis über die Zuordnung zu einer Fehlerklasse höherer Priorität zu führen.' }}
          </p>
          <p><span>5.</span>{{ 'Die spätere Umstufung einer Fehlermeldung in eine andere Fehlerklasse ist nur in beiderseitigem Einvernehmen möglich.' }}
          </p>
          <p><span>6.</span>{{ 'Sofern nicht ausdrücklich etwas anderes vereinbart ist, gilt für Fehlermeldungen innerhalb der Betriebszeiten der mitteldeutsche IT GmbH eine'
            + ' Reaktionszeit für Fehlermeldungen der Fehlerklasse 1 ein Zeitraum von 4 Stunden, für Fehlermeldungen der Fehlerklasse 2 ein Zeitraum von 6 Stunden, für'
            + ' Fehlermeldungen der Fehlerklasse 3 ein Zeitraum von einer Woche und für Fehler der Fehlerklasse 4 ein Zeitraum von zwei Wochen.' }}
          </p>
          <p><span>7.</span>{{ 'Es wird klargestellt, dass eine erfolgreiche Behebung der Funktionsbeeinträchtigung innerhalb der Reaktionszeiten nicht geschuldet ist'
            + '; Reaktionszeit bedeutet, dass sich die mitteldeutsche IT GmbH binnen der Reaktionszeit der Fehlermeldung annimmt und hiernach den Fehler zu beseitigen'
            + ' versucht.' }}
          </p>
          <p><span>8.</span>{{ 'Voraussetzung für den Beginn der Reaktionszeit ist auch, dass eine qualifizierte Fehlermeldung des Kunden am Helpdesk der mitteldeutsche'
            + ' IT GmbH per E-Mail (ticket@mitteldeutsche-it.de) oder über die in der Auftragsbestätigung aufgeführte Telefonnummer erfolgt. Qualifiziert ist eine Fehlermeldung'
            + ' dann, wenn der beschriebene Fehler reproduzierbar ist, d. h. die Bedienungssituation und die Arbeitsumgebung so genau beschrieben werden, dass ein'
            + ' qualifizierter Mitarbeiter der mitteldeutsche IT GmbH den Fehler jederzeit selbst reproduzieren, d. h. auslösen kann.' }}
          </p>
          <p>{{ 'Jede Fehlermeldung soll außerdem eine möglichst genaue Beschreibung der Funktionsbeeinträchtigung sowie den Zeitpunkt der ersten Feststellung'
            + ' enthalten. Tritt der Fehler nur an einzelnen Arbeitsplätzen auf, sind diese zu bezeichnen. Sofern der Fehler nach Veränderung des eigenen Systems des'
            + ' Kunden aufgetreten ist, ist dies ebenfalls mitzuteilen.' }}
          </p>
          <p><span>9.</span>{{ 'Gehen der mitteldeutsche IT GmbH Fehlermeldungen außerhalb ihrer Betriebszeiten zu, so beginnt die Reaktionszeit mit dem Beginn der nächsten'
            + ' Betriebszeit. Sofern zwischen den Parteien ausdrücklich eine Rufbereitschaft vereinbart ist, beginnt die Reaktionszeit bei Eingang der Fehlermeldung'
            + ' bei der Rufbereitschaft unter der dem Kunden mitgeteilten Notfallnummer.' }}
          </p>
          <p><span>10.</span>{{ 'Bei gravierenden Fehlern und Funktionsunterbrechungen der Fehlerklassen 1 und 2 wird im Anschluss an die Erstreaktion damit begonnen,'
            + ' die Folgen des Fehlers einzugrenzen. Solange der Fehler nicht beseitigt ist, gilt der Service nur dann als nicht verfügbar, wenn der Fehler nicht der'
            + ' Sphäre des Kunden zuzurechnen ist.' }}
          </p>
          <p>{{ 'Fehler der Fehlerklasse 3 oder 4 schränken die Verfügbarkeit der Leistung im Sinne des geschlossenen Vertrages nicht ein.' }}</p>
          <br>
          <h2>VIII. Preise und Zahlungen; Vergütung zusätzlicher Leistungen</h2>
          <hr color="#d02c2a">
          <p><span>1.</span>{{ 'Die mitteldeutsche IT GmbH rechnet ihre Leistungen, sofern nichts anderes vereinbart ist, monatlich ab. Die Rechnungen der mitteldeutsche IT GmbH'
            + ' sind sofort zur Zahlung fällig. Sofern nichts anderes vereinbart ist, ermächtigt der Kunde die mitteldeutsche IT GmbH, die von ihm zu leistenden Zahlungen zu'
            + ' Lasten eines vom Kunden zu benennenden Kontos einzuziehen.' }}
          </p>
          <p><span>2.</span>{{ 'Die Abrechnungen der mitteldeutsche IT GmbH erfolgen entsprechend der durch den Kunden jeweils in Anspruch genommenen (auch zusätzlichen)'
            + ' Dienste auf Grundlage der aktuellen Preisliste der mitteldeutsche IT GmbH.' }}
          </p>
          <p><span>3.</span>{{ 'Gerät der Kunde mit einer Zahlung für einen Abrechnungszeitraum ganz oder teilweise mehr als 20 Tage in Rückstand, so ist die'
            + ' mitteldeutsche IT GmbH berechtigt, den Zugriff des Kunden zum Rechenzentrum zu sperren. Unberührt hiervon bleibt die Pflicht des Kunden, die vereinbarten Zahlungen'
            + ' zu leisten.' }}
          </p>
          <br>
          <h2>IX. Kündigung des Vertrags</h2>
          <hr color="#d02c2a">
          <p><span>1.</span>{{ 'Der Kunde kann das Vertragsverhältnis jederzeit ohne Einhaltung einer Frist beenden. Einer ausdrücklichen Kündigung des Kunden steht'
            + ' es gleich, wenn der Kunde von der mitteldeutsche IT GmbH keine Leistungen mehr in Anspruch nimmt.' }}
          </p>
          <p><span>2.</span>{{ 'Die mitteldeutsche IT GmbH kann das Vertragsverhältnis mit dem Kunden ordentlich ohne Angabe von Gründen mit einer Frist von 3 Monaten zum'
            + ' Ende eines Monats kündigen.' }}
          </p>
          <p>{{ 'Die mitteldeutsche IT GmbH kann das Vertragsverhältnis außerordentlich fristlos kündigen bei Vorliegen eines wichtigen Grunds aus der Sphäre des Kunden.'
            + ' Ein wichtiger Grund liegt insb. vor, wenn' }}
          </p>
          <p style="margin-left: 30px;">{{ ' \u2022 der Kunde mit der Zahlung einer Rechnung ganz oder teilweise länger als einen Monat im Rückstand ist,' }}</p>
          <p style="margin-left: 30px;">{{ ' \u2022 der Kunde die ihm obliegenden Mitwirkungs- und Verhaltenspflichten in erheblichem Maße verletzt (z.B. die Dienste'
            + ' der mitteldeutsche IT GmbH zur Begehung von Rechtsverletzungen oder zur Begehung von Straftaten nutzt).' }}
          </p>
          <p>{{ 'Die Kündigung durch die mitteldeutsche IT GmbH erfolgt mittels Textform.' }}</p>
          <br>
          <h2>X. Gewährleistung; Haftung</h2>
          <hr color="#d02c2a">
          <p><span>1.</span>{{ 'Ist in Folge eines Mangels aus der Sphäre der mitteldeutsche IT GmbH die vertraglich vereinbarte oder – nachrangig – die in diesen'
            + ' Allgemeinen Geschäftsbedingungen bezeichnete Mindestverfügbarkeit (vgl. II.3. dieser Allgemeinen Vertragsbedingungen) unterschritten, so steht dem'
            + ' Kunden ein Minderungsrecht zu, sofern es sich um Fehler der Fehlerklassen 1 und 2 handelt.' }}
          </p>
          <p><span>2.</span>{{ 'Macht der Kunde sein Minderungsrecht geltend, so erfolgt die Berechnung der Minderung gemäß vorgehender Ziff. 1 anhand des Maßes der'
            + ' Unterschreitung der Verfügbarkeit/Jahr.' }}
          </p>
          <p><span>3.</span>{{ 'Überschreitet mitteldeutsche IT GmbH die gesondert vertraglich vereinbarte Wiederherstellungszeit bzw. in Ermangelung einer gesondert'
            + ' vertraglichen Regelung hierzu die nachfolgend bezeichneten Wiederherstellungszeiten' }}</p>
          <p style="margin-left: 30px;">{{ ' \u2022 bei Fehlerklasse 1: 6 Stunden nach Ablauf der Reaktionszeit,' }}</p>
          <p style="margin-left: 30px;">{{ ' \u2022 bei Fehlerklasse 2: 12 Stunden nach Ablauf der Reaktionszeit,' }}</p>
          <p style="margin-left: 30px;">{{ ' \u2022 bei Fehlerklasse 3: 24 Stunden nach Ablauf der Reaktionszeit sowie' }}</p>
          <p style="margin-left: 30px;">{{ ' \u2022 bei Fehlerklasse 4: 48 Stunden nach Ablauf der Reaktionszeit,' }}</p>
          <p>{{ 'so verpflichtet sich die mitteldeutsche IT GmbH, zugunsten des Kunden eine Gutschrift in Höhe von 25 % des für den Vormonat abgerechneten Nettobetrags zu'
            + ' erteilen.' }}</p>
          <p><span>4.</span>{{ 'Sofern der Kunde eine Minderung gemäß vorgehender Ziff. 1 geltend macht, wird die zugunsten des Kunden zu erteilende Gutschrift auf'
            + ' dessen Minderungsbetrag angerechnet.' }}
          </p>
          <p><span>5.</span>{{ 'Sofern die Nichtverfügbarkeit der Leistungen der mitteldeutsche IT GmbH auf ein Verhalten des Kunden oder aus dessen Sphäre bzw. der vom'
            + ' Kunden betriebenen Hardware oder von diesem verwendeter Software zurückzuführen ist, oder der Kunde Betriebs- oder Wartungsanweisungen nicht befolgte'
            + 'oder Änderungen an Hard- oder Software vornahm, so hat der Kunde keinen Anspruch auf Minderung gemäß vorgehender Ziff. 1 bzw. auf Erteilung einer'
            + ' Gutschrift gemäß vorgehender Ziff. 3.' }}
          </p>
          <p><span>6.</span>{{ 'Unwesentliche Abweichungen von Erscheinungsform, Darstellung, Farbe, Abmessungen und/oder anderen Qualitäts- und Leistungsmerkmalen'
            + ' der vertraglichen Leistungen der mitteldeutsche IT GmbH stellen keine Mängel dar.' }}
          </p>
          <p><span>7.</span>{{ 'Tritt ein Mangel auf, so ist der Kunde verpflichtet, dies der mitteldeutsche IT GmbH unverzüglich mitzuteilen. Vor Mitteilung abgelaufene'
            + ' Zeiträume bleiben bei der Berechnung der Verfügbarkeit sowie bei der Berechnung einer Minderung außer Betracht. Dies gilt entsprechend für dem Kunden'
            + ' zu erteilende Gutschriften gemäß vorgehender Ziff. 3.' }}
          </p>
          <p><span>8.</span>{{ 'Die Haftung der mitteldeutsche IT GmbH wegen eigener Pflichtverletzungen bzw. ihr zuzurechnender Pflichtverletzungen durch'
            + ' Erfüllungs-/Verrichtungsgehilfen ist bei Schäden, die nicht aus der Verletzung des Lebens, des Körpers oder der Gesundheit resultieren, auf vorsätzliche'
            + ' oder grob fahrlässige Pflichtverletzungen der mitteldeutsche IT GmbH, ihrer gesetzlichen Vertreter oder Erfüllungs- /Verrichtungsgehilfen beschränkt. Bei'
            + ' der Verletzung von Leben, Körper und Gesundheit haftet die mitteldeutsche IT GmbH auch für ihr zuzurechnende einfach fahrlässige Pflichtverletzungen ihres'
            + ' gesetzlichen Vertreters oder ihrer Erfüllungs-/Verrichtungsgehilfen.' }}
          </p>
          <p>{{ 'In Fällen deliktischer Haftung scheidet eine Haftung der mitteldeutsche IT GmbH aus nach Maßgabe der Voraussetzungen des § 831 Abs.1 BGB.' }}
          </p>
          <br>
          <h2>XI. durch Kunden gespeicherte Daten; Urheberrecht; personenbezogene Daten</h2>
          <hr color="#d02c2a">
          <p><span>1.</span>{{ 'Der Kunde ist verpflichtet, Rechtsverletzungen durch die bei der mitteldeutsche IT GmbH durch ihn verarbeiteten und gespeicherten Daten'
            + ', insbesondere Verstöße gegen Vorschriften auch des Strafrechts (z. B. des Jugendschutzes) zu unterlassen.' }}
          </p>
          <p>{{ 'Der Kunde ist ferner verpflichtet, den Urheberrechtsschutz Dritter nicht zu verletzen, insbesondere für die erforderlichen Lizenzen zur Nutzung'
            + ' urheberrechtlich geschützter Werke zu sorgen; wird die mitteldeutsche IT GmbH wegen einer urheberrechtlichen Pflichtverletzung des Kunden in Anspruch'
            + ' genommen, so stellt der Kunde die mitteldeutsche IT GmbH von dieser Inanspruchnahme auf erste Anforderung frei.' }}
          </p>
          <p><span>2.</span>{{ 'Entdeckt der Kunde illegale Inhalte in seinen Datenbeständen, so ist er zur umgehenden Mitteilung an die mitteldeutsche IT GmbH verpflichtet'
            + '; dies gilt auch dann, wenn diese Inhalte unbekannter Herkunft sind oder von Dritten stammen.' }}
          </p>
          <p><span>3.</span>{{ 'Erlangt die mitteldeutsche IT GmbH Kenntnis darüber, dass möglicherweise illegale Inhalte auf den vom Kunden gemieteten Diensten vorliegen,'
            + ' so ist die mitteldeutsche IT GmbH berechtigt, die Dienste des Kunden zu sperren; hierüber informiert die mitteldeutsche IT GmbH den Kunden. Geht der Kunde der'
            + ' Löschung dieser Daten nicht binnen 10 Tagen nach Aufforderung nach bzw. weist er nicht binnen dieser Frist nach, dass kein Gesetzesverstoß vorliegt, so'
            + ' ist die mitteldeutsche IT GmbH berechtigt, diese Daten auch ohne Zustimmung des Kunden zu löschen.' }}
          </p>
          <p><span>4.</span>{{ 'Der Kunde ist für die Einhaltung datenschutzrechtlicher Regelungen, insbesondere der Datenschutzgrundverordnung, selbst'
            + ' verantwortlich.' }}
          </p>
          <p><span>5.</span>{{ 'Soll die mitteldeutsche IT GmbH im Auftrag des Kunden von diesem übermittelte Daten verarbeiten, so weist sie den Kunden auf ihr bekannt'
            + ' gewordene datenschutzrechtliche Verstöße hin; der Kunde verpflichtet sich, einem solchen begründeten Hinweis unbedingt Folge zu leisten.' }}
          </p>
          <p><span>6.</span>{{ 'Die mitteldeutsche IT GmbH speichert und verwendet Daten des Kunden, wenn und soweit dies für die Abrechnung erforderlich ist. Nach'
            + ' Abrechnung und Bezahlung durch den Kunden werden die Daten nach Maßgabe der Datenschutzgrundverordnung gelöscht, sofern nicht der Kunde eine Speicherung'
            + ' seiner Verkehrsdaten von mitteldeutsche IT GmbH verlangt und auch unter Berücksichtigung datenschutzrechtlicher Regelungen verlangen darf.' }}
          </p>
          <br>
          <h2>XII. Datensicherheit</h2>
          <hr color="#d02c2a">
          <p><span>1.</span>{{ 'Die auf den Systemen der mitteldeutsche IT GmbH gespeicherten Daten des Kunden werden, sofern die mitteldeutsche IT GmbH hiermit'
            + 'beauftragt ist, täglich gesichert. Die Sicherung erfolgt nach Maßgabe der hierzu getroffenen Vereinbarung.' }}
          </p>
          <p><span>2.</span>{{ 'Sofern eine Sicherung vereinbart wurde, wird die mitteldeutsche IT GmbH während der Vertragslaufzeit eine Kopie der vom Kunden auf den ihm'
            + ' zugewiesenen Speicherplatz abgelegten Daten jederzeit, spätestens mit Beendigung des Vertragsverhältnisses unverzüglich herausgeben. Die Herausgabe der'
            + ' Daten erfolgt per Datenfernübertragung in dem Datenformat, in dem die Daten auf dem Datenserver der mitteldeutsche IT GmbH abgelegt sind, sofern nicht ein'
            + ' anderes Datenformat zwischen den Parteien vereinbart wurde.' }}
          </p>
          <p><span>3.</span>{{ 'Die mitteldeutsche IT GmbH ist nicht berechtigt, die durch den Kunden bei ihr abgelegten Daten einzusehen. Die gilt nicht, sofern der Kunde'
            + ' eine Dateneinsicht verlangt und dies zur Sicherung der technischen Funktionen der Dienste der mitteldeutsche IT GmbH erforderlich ist.' }}
          </p>
          <p><span>4.</span>{{ 'Der Kunde verwahrt die Zugangsdaten zu den Systemen der mitteldeutsche IT GmbH sicher, insbesondere trägt er dafür Sorge, dass sie nicht an'
            + ' Dritte weitergegeben werden. Hat der Kunde Anlass zur Besorgnis hierzu, so teilt er dies der mitteldeutsche IT GmbH unverzüglich mit.' }}
          </p>
          <p><span>5.</span>{{ 'Der Kunde übermittelt an die mitteldeutsche IT GmbH nur Daten, die zuvor durch ihn mit aktuellen Mitteln (z. B. Virenschutzprogramme)'
            + ' geprüft wurden.' }}
          </p>
          <br>
          <h2>XIII. Rechtswahl; Gerichtsstand</h2>
          <hr color="#d02c2a"/>
          <p><span>1.</span>{{ 'Für Streitigkeiten zwischen den Parteien gilt deutsches Recht.' }}
          </p>
          <p><span>2.</span>{{ 'Ist der Kunde Kaufmann, so ist für alle Streitigkeiten aus dem zwischen den Parteien geschlossenen Vertrag Leipzig als Gerichtsstand'
            + ' vereinbart.' }}
          </p>
        </div>
        <template #footer>
          <a-checkbox :defaultChecked="false" v-model:checked="iReadtheAgbs"></a-checkbox>
          <span class="hoverPointer" @click="clickCheckbox()" style="margin-left: 5px; margin-right: 30px;">Ich habe die AGB gelesen und und bin mit deren Geltung einverstanden.</span>
          <a-button @click="closeModal()">Ablehnen</a-button>
          <a-button :disabled="!iReadtheAgbs" type="primary" @click="handleSubmit">Annehmen</a-button>
        </template>
      </a-modal>
    </div>
</template>  

<script>
export default {
  name: 'AGBs',
  props: {
    showAgbModal: {
      type: Boolean,
      default: false
    },
  },
  data () {
    return {
        iReadtheAgbs: false,
    }
  },
  methods: {
    closeModal() {
        this.iReadtheAgbs = false
        this.$emit('closeModal')
    },
    handleSubmit() {
        this.iReadtheAgbs = false
        this.$emit('agbSubmit')
    },
    clickCheckbox () {
      if (this.iReadtheAgbs) {
        this.iReadtheAgbs = false
      } else {
        this.iReadtheAgbs = true
      }
    }
  }
}
</script>

<style scoped>
.agbContent {
  overflow-y:scroll;
  height: 78vh;
  width: 101.5%;
}
.agbContent h2 {
  color: black;
}

.agbContent span {
  margin-right: 20px;
}

.hoverPointer:hover {
  cursor: pointer;
}
</style>